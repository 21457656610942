import { useMemo } from 'react';
import { useRightPanelContext } from '../context/RightPanelContext.js';
import { usePanelState } from './usePanelState.js';
import { usePanelActions } from './usePanelActions.js';

export function usePanel() {
    const context = useRightPanelContext();
    const state = usePanelState();
    const actions = usePanelActions();

    const panel = useMemo(() => ({
        ...state,
        ...actions,
        dispatch: context.dispatch,
    }), [state, actions, context.dispatch]);

    return panel;
}

export default usePanel;