// button.jsx

import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { cn } from "../utils/utils.js";
import { Loader2, Sparkles } from "lucide-react";

const buttonVariants = cva(
    "relative inline-flex items-center justify-center gap-2 rounded-md text-sm font-medium transition-all duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary/50 focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background select-none cursor-pointer",
    {
        variants: {
            variant: {
                default: [
                    "bg-gradient-to-r from-primary-dark to-primary",
                    "text-white",
                    "shadow-[0_0_0_1px_rgba(32,178,170,0.1),0_4px_12px_rgba(32,178,170,0.2)]",
                    "hover:shadow-[0_0_0_1px_rgba(32,178,170,0.2),0_8px_24px_rgba(32,178,170,0.4)]",
                    "hover:from-primary hover:to-primary-lighter",
                    "active:scale-[0.98]",
                ],
                destructive: [
                    "bg-gradient-to-r from-red-600 to-red-500",
                    "text-white",
                    "shadow-[0_0_0_1px_rgba(239,68,68,0.1),0_4px_12px_rgba(239,68,68,0.2)]",
                    "hover:shadow-[0_0_0_1px_rgba(239,68,68,0.2),0_8px_24px_rgba(239,68,68,0.4)]",
                    "hover:from-red-500 hover:to-red-400",
                    "active:scale-[0.98]",
                ],
                outline: [
                    "border border-gray-700",
                    "bg-gradient-to-r from-gray-900/50 to-gray-900/30",
                    "text-gray-300",
                    "backdrop-blur-sm",
                    "hover:border-primary/50",
                    "hover:bg-primary/10",
                    "hover:text-gray-100",
                    "active:scale-[0.98]",
                ],
                ghost: [
                    "text-gray-300",
                    "hover:bg-primary/10",
                    "hover:text-gray-100",
                    "active:scale-[0.98]",
                ],
                link: [
                    "text-primary",
                    "underline-offset-4",
                    "hover:text-primary-lighter hover:underline",
                ],
                glass: [
                    "bg-white/10",
                    "backdrop-blur-md",
                    "border border-white/20",
                    "text-white",
                    "shadow-[0_0_0_1px_rgba(255,255,255,0.05)]",
                    "hover:bg-white/20",
                    "hover:border-white/30",
                    "active:scale-[0.98]",
                ],
                premium: [
                    "bg-gradient-to-r from-primary-dark via-primary to-primary-lighter",
                    "text-white",
                    "shadow-[0_0_0_1px_rgba(32,178,170,0.1),0_4px_12px_rgba(32,178,170,0.2)]",
                    "hover:shadow-[0_0_0_1px_rgba(32,178,170,0.2),0_8px_24px_rgba(32,178,170,0.4)]",
                    "hover:from-primary hover:via-primary-lighter hover:to-primary-lighter",
                    "active:scale-[0.98]",
                    "before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-white/20 before:to-transparent before:opacity-0 before:hover:opacity-100 before:transition-opacity before:duration-500",
                    "overflow-hidden",
                ],
            },
            size: {
                default: "h-10 px-4 py-2",
                sm: "h-9 px-3 rounded-md text-xs",
                lg: "h-11 px-6 rounded-md text-base",
                xl: "h-12 px-8 rounded-lg text-lg",
                icon: [
                    "h-10 w-10 rounded-md",
                    "hover:rotate-12 transition-transform duration-200",
                ],
                "icon-sm": [
                    "h-8 w-8 rounded-md",
                    "hover:rotate-12 transition-transform duration-200",
                ],
                "icon-lg": [
                    "h-12 w-12 rounded-md",
                    "hover:rotate-12 transition-transform duration-200",
                ],
            },
            loading: {
                true: "relative !text-transparent transition-none hover:!text-transparent cursor-wait",
            },
            block: {
                true: "w-full",
            },
            glow: {
                true: "animate-pulse-glow",
            },
            shimmer: {
                true: "before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-white/20 before:to-transparent before:animate-shimmer",
            },
        },
        compoundVariants: [
            {
                loading: true,
                class: "cursor-wait",
            },
            {
                variant: "premium",
                loading: true,
                class: "before:hidden",
            },
        ],
        defaultVariants: {
            variant: "default",
            size: "default",
            loading: false,
            block: false,
            glow: false,
            shimmer: false,
        },
    }
);

const LoadingSpinner = () => (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <Loader2 className="h-5 w-5 animate-spin" />
    </div>
);

const PremiumSparkle = () => (
    <Sparkles className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-yellow-300 animate-pulse" />
);

const Button = React.forwardRef(
    ({
         className,
         variant,
         size,
         loading = false,
         block = false,
         glow = false,
         shimmer = false,
         asChild = false,
         children,
         ...props
     }, ref) => {
        const Comp = asChild ? Slot : "button";
        const isPremium = variant === "premium";

        return (
            <Comp
                className={cn(
                    buttonVariants({
                        variant,
                        size,
                        loading,
                        block,
                        glow,
                        shimmer,
                        className
                    })
                )}
                ref={ref}
                disabled={props.disabled || loading}
                {...props}
            >
                {children}
                {loading && <LoadingSpinner />}
                {isPremium && !loading && <PremiumSparkle />}
            </Comp>
        );
    }
);

Button.displayName = "Button";

export { Button, buttonVariants };
