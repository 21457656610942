import React, { createContext, useContext, useState, useCallback } from 'react';
import { apiService } from './api.config.js';

const ApiContext = createContext();

export const useApi = () => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useApi must be used within an ApiProvider');
    }
    return context;
};

export const ApiProvider = ({ children }) => {
    const [responses, setResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const processResponse = useCallback((apiResponse) => {
        const timestamp = new Date().toISOString();
        return [
            {
                id: `user-${Date.now()}`,
                timestamp,
                role: 'user',
                content: apiResponse.userMessage,
                type: 'user'
            },
            ...(apiResponse.anthropic ? [{
                id: `claude-${Date.now()}`,
                timestamp,
                role: 'assistant',
                content: apiResponse.anthropic.content,
                provider: 'Claude',
                model: apiResponse.anthropic.model,
                type: 'anthropic'
            }] : []),
            ...(apiResponse.openai ? [{
                id: `openai-${Date.now()}`,
                timestamp,
                role: 'assistant',
                content: apiResponse.openai.content,
                provider: 'OpenAI',
                model: apiResponse.openai.model,
                type: 'openai'
            }] : [])
        ];
    }, []);

    const handleSubmit = useCallback(async (inputContent) => {
        if (!inputContent?.trim()) return;

        setIsLoading(true);
        setError(null);

        try {
            const response = await apiService.ai.createResponse([{
                role: 'user',
                content: inputContent.trim()
            }]);

            if (response.data) {
                const newResponses = processResponse({
                    userMessage: inputContent.trim(),
                    ...response.data
                });
                setResponses(prev => [...prev, ...newResponses]);
                return newResponses;
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error ||
                (err.message === 'Network Error' ? 'Unable to connect to API' : err.message) ||
                'Failed to get response';
            setError(errorMessage);
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, [processResponse]);

    const onWinnerSelected = useCallback(async (timestamp, winnerType) => {
        try {
            setResponses(prev =>
                prev.map(response => ({
                    ...response,
                    isWinner: response.timestamp === timestamp && response.type === winnerType
                }))
            );
        } catch (error) {
            console.error('Error selecting winner:', error);
            setError('Failed to select winner');
        }
    }, []);

    const value = {
        responses,
        isLoading,
        error,
        setError,
        onSubmit: handleSubmit,
        onWinnerSelected,
        hasResponses: responses.length > 0,
        canSubmit: !isLoading
    };

    return (
        <ApiContext.Provider value={value}>
            {children}
        </ApiContext.Provider>
    );
};

export { ApiContext };