// label.js //
import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cn } from "../utils/utils.js";
import { cva } from "class-variance-authority";
import { Info } from 'lucide-react'; // Added icons for labels
import '../styles/App.css';

const labelVariants = cva(
    "relative inline-flex items-center gap-2 select-none leading-none font-medium tracking-tight transition-colors duration-200",
    {
        variants: {
            variant: {
                default: [
                    "text-gray-200 hover:text-gray-100",
                ],
                filled: [
                    "px-3 py-2 rounded-md bg-gradient-to-r from-gray-900/90 to-gray-900/80 border border-gray-800 shadow-sm text-gray-200 hover:bg-gradient-to-r from-gray-900/80 to-gray-900/70",
                ],
                outlined: [
                    "px-3 py-2 rounded-md border border-gray-700 text-gray-200 hover:border-primary/50 hover:text-gray-100",
                ],
                glowing: [
                    "text-primary-400 relative after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-gradient-to-r after:from-primary-500/50 after:to-transparent after:origin-right after:scale-x-0 after:transition-transform after:duration-300 hover:after:origin-left hover:after:scale-x-100",
                ],
                premium: [
                    "px-3 py-2 rounded-md bg-gradient-to-r from-gray-900/90 to-gray-900/80 border border-primary-500/20 text-primary-300 shadow-neon hover:shadow-glow",
                ],
                glass: [
                    "px-3 py-2 rounded-md bg-white/5 backdrop-blur-md border border-white/10 text-gray-200 hover:bg-white/10",
                ],
            },
            size: {
                sm: "text-xs",
                default: "text-sm",
                lg: "text-base",
                xl: "text-lg",
            },
            required: {
                true: "after:content-['*'] after:ml-0.5 after:text-primary-500",
            },
            disabled: {
                true: "opacity-50 cursor-not-allowed",
            },
            glow: {
                true: "animate-pulse-glow",
            },
        },
        compoundVariants: [
            {
                variant: ["filled", "outlined", "premium", "glass"],
                size: "sm",
                class: "px-2 py-1",
            },
            {
                variant: ["filled", "outlined", "premium", "glass"],
                size: "lg",
                class: "px-3 py-2",
            },
            {
                variant: ["filled", "outlined", "premium", "glass"],
                size: "xl",
                class: "px-4 py-2.5",
            },
        ],
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
);
const Label = React.forwardRef(({
                                    className,
                                    variant,
                                    size,
                                    required,
                                    disabled,
                                    glow,
                                    icon: Icon = Info,  // Default to Info, but allow Star if passed
                                    children,
                                    ...props
                                }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={cn(
            labelVariants({
                variant,
                size,
                required,
                disabled,
                glow,
                className
            })
        )}
        {...props}
    >
        {Icon && (
            <Icon
                className={cn(
                    "h-4 w-4",
                    size === "sm" && "h-3 w-3",
                    size === "lg" && "h-5 w-5",
                    size === "xl" && "h-6 w-6",
                    variant === "premium" && "text-primary-400",
                    "transition-colors duration-200"
                )}
            />
        )}
        {children}
    </LabelPrimitive.Root>
));

Label.displayName = "Label";

// Optional: Additional utility components
const RequiredIndicator = () => (
    <span className="text-primary-500 ml-0.5" aria-hidden="true">
    *
  </span>
);

const OptionalIndicator = () => (
    <span className="text-gray-500 text-xs ml-1" aria-hidden="true">
    (optional)
  </span>
);

export { Label, RequiredIndicator, OptionalIndicator };
