import React from 'react';
import PropTypes from 'prop-types';
import {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    List,
    ListOrdered,
    Heading1,
    Heading2,
    Link,
    Image,
    Table,
} from 'lucide-react';
import { Button } from '../../../../../App/components/ui/button.jsx';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../../../../App/components/ui/select.js';
import './WritingPanel.css';

const FONTS = [
    { name: 'Arial', value: 'Arial, sans-serif' },
    { name: 'Times New Roman', value: 'Times New Roman, serif' },
    { name: 'Calibri', value: 'Calibri, sans-serif' },
    { name: 'Georgia', value: 'Georgia, serif' },
    { name: 'Verdana', value: 'Verdana, sans-serif' },
];

const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48, 72];

function WritingPanel({ onFormatChange, currentFormats }) {
    return (
        <div className="doc-editing-panel">
            <div className="panel-section">
                <h3 className="section-title">Font</h3>
                <div className="font-controls">
                    <Select
                        value={currentFormats?.fontFamily || FONTS[0].value}
                        onValueChange={(value) => onFormatChange('fontFamily', value)}
                    >
                        <SelectTrigger className="font-select">
                            <SelectValue placeholder="Select font" />
                        </SelectTrigger>
                        <SelectContent>
                            {FONTS.map((font) => (
                                <SelectItem
                                    key={font.value}
                                    value={font.value}
                                    style={{ fontFamily: font.value }}
                                >
                                    {font.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                    <Select
                        value={currentFormats?.fontSize || '12'}
                        onValueChange={(value) => onFormatChange('fontSize', value)}
                    >
                        <SelectTrigger className="font-size-select">
                            <SelectValue placeholder="Size" />
                        </SelectTrigger>
                        <SelectContent>
                            {FONT_SIZES.map((size) => (
                                <SelectItem key={size} value={size.toString()}>
                                    {size}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                <div className="format-buttons">
                    <Button
                        variant="ghost"
                        size="sm"
                        className={`format-button ${currentFormats?.bold ? 'active' : ''}`}
                        onClick={() => onFormatChange('bold')}
                    >
                        <Bold className="icon" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="sm"
                        className={`format-button ${currentFormats?.italic ? 'active' : ''}`}
                        onClick={() => onFormatChange('italic')}
                    >
                        <Italic className="icon" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="sm"
                        className={`format-button ${
                            currentFormats?.underline ? 'active' : ''
                        }`}
                        onClick={() => onFormatChange('underline')}
                    >
                        <Underline className="icon" />
                    </Button>
                </div>

                <div className="text-align-buttons">
                    <Button
                        variant="ghost"
                        size="sm"
                        className="align-button"
                        onClick={() => onFormatChange('align', 'left')}
                    >
                        <AlignLeft className="icon" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="sm"
                        className="align-button"
                        onClick={() => onFormatChange('align', 'center')}
                    >
                        <AlignCenter className="icon" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="sm"
                        className="align-button"
                        onClick={() => onFormatChange('align', 'right')}
                    >
                        <AlignRight className="icon" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="sm"
                        className="align-button"
                        onClick={() => onFormatChange('align', 'justify')}
                    >
                        <AlignJustify className="icon" />
                    </Button>
                </div>
            </div>

            <div className="panel-section">
                <h3 className="section-title">Insert</h3>
                <div className="insert-buttons">
                    <Button variant="ghost" size="sm" className="insert-button">
                        <Image className="icon" />
                        <span>Image</span>
                    </Button>
                    <Button variant="ghost" size="sm" className="insert-button">
                        <Table className="icon" />
                        <span>Table</span>
                    </Button>
                    <Button variant="ghost" size="sm" className="insert-button">
                        <Link className="icon" />
                        <span>Link</span>
                    </Button>
                </div>
            </div>

            <div className="panel-section">
                <h3 className="section-title">Lists</h3>
                <div className="list-buttons">
                    <Button variant="ghost" size="sm" className="list-button">
                        <List className="icon" />
                        <span>Bullet List</span>
                    </Button>
                    <Button variant="ghost" size="sm" className="list-button">
                        <ListOrdered className="icon" />
                        <span>Numbered List</span>
                    </Button>
                </div>
            </div>

            <div className="panel-section">
                <h3 className="section-title">Styles</h3>
                <div className="style-buttons">
                    <Button variant="ghost" size="sm" className="style-button">
                        <Heading1 className="icon" />
                        <span>Heading 1</span>
                    </Button>
                    <Button variant="ghost" size="sm" className="style-button">
                        <Heading2 className="icon" />
                        <span>Heading 2</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}

WritingPanel.propTypes = {
    onFormatChange: PropTypes.func.isRequired,
    currentFormats: PropTypes.object,
};

WritingPanel.defaultProps = {
    currentFormats: {},
};

export default WritingPanel;