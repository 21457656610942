// PanelContext.js
import React, { createContext, useState } from 'react';

export const PanelContext = createContext();

export const PanelProvider = ({ children }) => {
    const [activePanel, setActivePanel] = useState('default');

    return (
        <PanelContext.Provider value={{ activePanel, setActivePanel }}>
            {children}
        </PanelContext.Provider>
    );
};
