import { useCallback } from 'react';
import { useRightPanelContext } from '../context/RightPanelContext.js';
import { ActionTypes } from '../context/RightPanelReducer.js';
import { ANIMATION_DURATION } from '../constants/constants.js';

export function usePanelActions() {
    const { dispatch } = useRightPanelContext();

    const setActiveTab = useCallback((tab) => {
        dispatch({ type: ActionTypes.SET_ACTIVE_TAB, payload: tab });
    }, [dispatch]);

    const toggleFloating = useCallback(() => {
        dispatch({ type: ActionTypes.TOGGLE_FLOATING });
    }, [dispatch]);

    const toggleExpanded = useCallback(() => {
        dispatch({ type: ActionTypes.TOGGLE_EXPANDED });
    }, [dispatch]);

    const setActiveView = useCallback((view) => {
        dispatch({ type: ActionTypes.SET_ACTIVE_VIEW, payload: view });
    }, [dispatch]);

    const updateNodes = useCallback((nodes) => {
        dispatch({ type: ActionTypes.UPDATE_NODES, payload: nodes });
    }, [dispatch]);

    const updateEdges = useCallback((edges) => {
        dispatch({ type: ActionTypes.UPDATE_EDGES, payload: edges });
    }, [dispatch]);

    const setSelectedDiagram = useCallback((diagram) => {
        dispatch({ type: ActionTypes.SET_SELECTED_DIAGRAM, payload: diagram });
    }, [dispatch]);

    const setSelectedEdge = useCallback((edge) => {
        dispatch({ type: ActionTypes.SET_SELECTED_EDGE, payload: edge });
    }, [dispatch]);

    const setLoading = useCallback((isLoading) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: isLoading });
    }, [dispatch]);

    const showError = useCallback((message) => {
        dispatch({ type: ActionTypes.SET_ERROR, payload: message });
        setTimeout(() => {
            dispatch({ type: ActionTypes.SET_ERROR, payload: null });
        }, ANIMATION_DURATION.NORMAL);
    }, [dispatch]);

    const showSuccess = useCallback((message) => {
        dispatch({ type: ActionTypes.SET_SUCCESS_MESSAGE, payload: message });
        setTimeout(() => {
            dispatch({ type: ActionTypes.SET_SUCCESS_MESSAGE, payload: null });
        }, ANIMATION_DURATION.NORMAL);
    }, [dispatch]);

    const updateMessageGroups = useCallback((groups) => {
        dispatch({ type: ActionTypes.UPDATE_MESSAGE_GROUPS, payload: groups });
    }, [dispatch]);

    const setActiveResponseGroup = useCallback((groupId) => {
        dispatch({ type: ActionTypes.SET_ACTIVE_RESPONSE_GROUP, payload: groupId });
    }, [dispatch]);

    const addAIResponse = useCallback((response) => {
        dispatch({ type: ActionTypes.ADD_AI_RESPONSE, payload: response });
    }, [dispatch]);

    const markUnsavedChanges = useCallback(() => {
        dispatch({ type: ActionTypes.MARK_UNSAVED_CHANGES });
    }, [dispatch]);

    const clearUnsavedChanges = useCallback(() => {
        dispatch({ type: ActionTypes.CLEAR_UNSAVED_CHANGES });
    }, [dispatch]);

    const resetPanel = useCallback(() => {
        dispatch({ type: ActionTypes.RESET_PANEL });
    }, [dispatch]);

    const setAIMode = useCallback((enabled) => {
        dispatch({ type: ActionTypes.SET_AI_MODE, payload: enabled });
    }, [dispatch]);

    return {
        setActiveTab,
        toggleFloating,
        toggleExpanded,
        setActiveView,
        updateNodes,
        updateEdges,
        setSelectedDiagram,
        setSelectedEdge,
        setLoading,
        showError,
        showSuccess,
        updateMessageGroups,
        setActiveResponseGroup,
        addAIResponse,
        markUnsavedChanges,
        clearUnsavedChanges,
        resetPanel,
        setAIMode,
    };
}

export default usePanelActions;