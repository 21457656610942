// dropdown-menu.js //
import * as React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { cn } from '../utils/utils.js';
import { ChevronDown, Check } from 'lucide-react'; // Added icons for menu items

const DropdownMenu = DropdownMenuPrimitive.Root;

const DropdownMenuTrigger = React.forwardRef(({ className, children, ...props }, ref) => (
    <DropdownMenuPrimitive.Trigger
        ref={ref}
        className={cn(
            "inline-flex items-center justify-center rounded-md bg-gradient-to-r from-primary-dark to-primary text-white px-4 py-2 text-sm font-medium hover:from-primary-medium hover:to-primary-light focus:outline-none focus:ring-2 focus:ring-primary/50 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-300 cursor-pointer",
            className
        )}
        {...props}
    >
        <>
            {children}
            <ChevronDown className="ml-2 h-4 w-4" />
        </>
    </DropdownMenuPrimitive.Trigger>
));
DropdownMenuTrigger.displayName = DropdownMenuPrimitive.Trigger.displayName;


const DropdownMenuContent = React.forwardRef(({ className, sideOffset = 4, ...props }, ref) => (
    <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
            ref={ref}
            sideOffset={sideOffset}
            className={cn(
                "z-50 min-w-[10rem] overflow-hidden rounded-lg border border-primary-medium bg-gray-900/90 p-2 shadow-neon animate-in fade-in-0 slide-in-from-top-1",
                className
            )}
            {...props}
        />
    </DropdownMenuPrimitive.Portal>
));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = React.forwardRef(({ className, inset, check, ...props }, ref) => (
    <DropdownMenuPrimitive.Item
        ref={ref}
        className={cn(
            "relative flex cursor-pointer select-none items-center rounded-md px-4 py-2 text-sm font-semibold text-gray-100 transition-colors duration-200 hover:bg-primary-medium hover:text-white focus:outline-none focus:bg-primary-medium",
            inset && "pl-8",
            className
        )}
        {...props}
    >
        {check ? <Check className="mr-2 h-4 w-4 text-green-400" /> : null}
        {props.children}
    </DropdownMenuPrimitive.Item>
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const DropdownMenuSeparator = React.forwardRef(({ className, ...props }, ref) => (
    <DropdownMenuPrimitive.Separator
        ref={ref}
        className={cn("my-1 h-px bg-gray-700", className)}
        {...props}
    />
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const DropdownMenuLabel = React.forwardRef(({ className, ...props }, ref) => (
    <DropdownMenuPrimitive.Label
        ref={ref}
        className={cn(
            "px-4 py-2 text-sm font-semibold text-gray-300",
            className
        )}
        {...props}
    />
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

export {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuLabel,
};
