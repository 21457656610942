// RightPanel/components/Panels/NodesPanel/NodesPanel.js

import React from 'react';
import PropTypes from 'prop-types';
import './NodesPanel.css';

// Define the ten node types with their configurations
const NODE_TYPES_CONFIG = {
    rectangle: {
        label: 'Rectangle',
        description: 'A standard rectangular node',
        color: '#20b2aa',
        shape: 'rectangle',
        icon: '▭',
    },
    circle: {
        label: 'Circle',
        description: 'A circular node',
        color: '#20b2aa',
        shape: 'circle',
        icon: '●',
    },
    diamond: {
        label: 'Diamond',
        description: 'A diamond-shaped node',
        color: '#20b2aa',
        shape: 'diamond',
        icon: '◆',
    },
    parallelogram: {
        label: 'Parallelogram',
        description: 'Represents input/output',
        color: '#20b2aa',
        shape: 'parallelogram',
        icon: '▱',
    },
    hexagon: {
        label: 'Hexagon',
        description: 'A hexagonal node',
        color: '#20b2aa',
        shape: 'hexagon',
        icon: '⬡',
    },
    star: {
        label: 'Star',
        description: 'A star-shaped node',
        color: '#20b2aa',
        shape: 'star',
        icon: '★',
    },
    trapezoid: {
        label: 'Trapezoid',
        description: 'A trapezoid node',
        color: '#20b2aa',
        shape: 'trapezoid',
        icon: '⌓',
    },
    oval: {
        label: 'Oval',
        description: 'An oval-shaped node',
        color: '#20b2aa',
        shape: 'oval',
        icon: '⬭',
    },
    cloud: {
        label: 'Cloud',
        description: 'Represents external data',
        color: '#20b2aa',
        shape: 'cloud',
        icon: '☁',
    },
    cylinder: {
        label: 'Cylinder',
        description: 'Represents a database',
        color: '#20b2aa',
        shape: 'cylinder',
        icon: '⌛',
    },
};
function NodesPanel() {
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <div className="nodes-panel">
            <h3 className="section-title">Drag & Drop Nodes</h3>
            <div className="node-types-grid">
                {Object.entries(NODE_TYPES_CONFIG).map(([type, config]) => (
                    <div
                        key={type}
                        className="node-type-card"
                        draggable
                        onDragStart={(event) => onDragStart(event, type)}
                    >
                        <div
                            className={`node-shape node-shape-${config.shape}`}
                            style={{ backgroundColor: config.color }}
                        >
                            <span>{config.icon}</span>
                        </div>
                        <div className="node-type-info">
                            <span className="node-type-label">{config.label}</span>
                            <p className="node-type-description">{config.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

NodesPanel.propTypes = {
    nodeTypes: PropTypes.object,
};

export default NodesPanel;
