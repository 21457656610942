// WritingPlatform.js
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './WritingPlatform.css';

function WritingPlatform({ onChange, content }) {
    const [editorState, setEditorState] = useState(() => {
        if (content) {
            try {
                const contentState = convertFromRaw(JSON.parse(content));
                return EditorState.createWithContent(contentState);
            } catch (e) {
                return EditorState.createEmpty();
            }
        }
        return EditorState.createEmpty();
    });

    useEffect(() => {
        if (content) {
            try {
                const contentState = convertFromRaw(JSON.parse(content));
                setEditorState(EditorState.createWithContent(contentState));
            } catch (e) {
                console.error('Error loading content:', e);
            }
        }
    }, [content]);

    const handleChange = useCallback((newEditorState) => {
        setEditorState(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        onChange(JSON.stringify(rawContent));
    }, [onChange]);

    const handleKeyCommand = useCallback((command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            handleChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }, [handleChange]);

    return (
        <div className="writing-platform">
            <div className="document-page">
                <Editor
                    editorState={editorState}
                    onChange={handleChange}
                    handleKeyCommand={handleKeyCommand}
                    placeholder="Start writing..."
                    spellCheck={true}
                />
            </div>
        </div>
    );
}

WritingPlatform.propTypes = {
    onChange: PropTypes.func.isRequired,
    content: PropTypes.string
};

export default WritingPlatform;