// progress.js //
import React, { useRef, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

// Advanced Animations
const shimmer = keyframes`
    0% { background-position: -200% center; }
    100% { background-position: 200% center; }
`;


const glow = keyframes`
    0%, 100% { filter: brightness(1); }
    50% { filter: brightness(1.2); }
`;

// Styled Components with Enhanced Effects
const ProgressWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: ${props => props.labelGap || '12px'};
`;

const ProgressBarContainer = styled.div`
    width: 100%;
    position: relative;
    background: ${props => props.variant === 'glass'
            ? 'rgba(255, 255, 255, 0.1)'
            : props.variant === 'premium'
                    ? 'linear-gradient(145deg, rgba(18, 18, 18, 0.95), rgba(18, 18, 18, 0.9))'
                    : 'rgba(18, 18, 18, 0.9)'};
    border-radius: ${props => props.borderRadius || '12px'};
    height: ${props => props.height || '8px'};
    overflow: hidden;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(32, 178, 170, 0.1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

    ${props => props.variant === 'glass' && css`
        backdrop-filter: blur(12px);
        border: 1px solid rgba(255, 255, 255, 0.1);
    `}

    ${props => props.variant === 'premium' && css`
        background: linear-gradient(145deg, rgba(18, 18, 18, 0.95), rgba(18, 18, 18, 0.9));
        border: 1px solid rgba(32, 178, 170, 0.2);
        box-shadow:
                0 0 0 1px rgba(32, 178, 170, 0.1),
                0 4px 12px rgba(32, 178, 170, 0.2);
    `}
`;

const ProgressFill = styled.div`
    height: 100%;
    width: ${props => props.progress || '0%'};
    position: relative;
    transition: width 0.6s cubic-bezier(0.4, 0, 0.2, 1);

    ${props => {
        switch (props.variant) {
            case 'glass':
                return css`
                    background: linear-gradient(
                            90deg,
                            rgba(32, 178, 170, 0.3),
                            rgba(32, 178, 170, 0.6)
                    );
                    box-shadow: 0 0 20px rgba(32, 178, 170, 0.3);
                `;
            case 'gradient':
                return css`
                    background: linear-gradient(
                            90deg,
                            #20b2aa,
                            #20b2aa 45%,
                            #25c4bb
                    );
                `;
            case 'premium':
                return css`
                    background: linear-gradient(
                            90deg,
                            #20b2aa,
                            #25c4bb,
                            #20b2aa
                    );
                    background-size: 200% 100%;
                    animation: ${shimmer} 3s linear infinite;
                    box-shadow: 0 0 20px rgba(32, 178, 170, 0.3);
                `;
            default:
                return css`
                    background: #20b2aa;
                `;
        }
    }}

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
                90deg,
                transparent,
                rgba(255, 255, 255, 0.2),
                transparent
        );
        transform: translateX(-100%);
        animation: ${shimmer} 2s infinite;
    }
`;

const ProgressLabel = styled.span`
    font-size: ${props => props.fontSize || '0.875rem'};
    font-weight: 500;
    color: ${props => props.variant === 'glass' ? 'rgba(255, 255, 255, 0.9)' : '#fff'};
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.025em;
    min-width: 48px;
    text-align: right;

    ${props => props.glowing && css`
        animation: ${glow} 2s ease-in-out infinite;
    `}
`;

const Milestone = styled.div`
    position: absolute;
    top: 50%;
    left: ${props => props.position};
    transform: translateY(-50%);
    width: 2px;
    height: ${props => props.height || '16px'};
    background: rgba(32, 178, 170, 0.6);

    &::after {
        content: '${props => props.label}';
        position: absolute;
        top: -24px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.75rem;
        color: rgba(255, 255, 255, 0.8);
        white-space: nowrap;
        pointer-events: none;
        background: rgba(0, 0, 0, 0.6);
        padding: 2px 6px;
        border-radius: 4px;
    }
`;

// Enhanced Progress Component
const Progress = ({
                      progress,
                      variant = 'default',
                      height,
                      borderRadius,
                      showLabel,
                      fontSize,
                      glowing,
                      milestones,
                      labelGap,
                      className,
                      ...props
                  }) => {
    const progressRef = useRef(null);

    useEffect(() => {
        if (progressRef.current && variant === 'premium') {
            // Add any premium animations or effects if needed
        }
    }, [variant, progress]);

    return (
        <ProgressWrapper labelGap={labelGap} className={className}>
            <ProgressBarContainer
                variant={variant}
                height={height}
                borderRadius={borderRadius}
                {...props}
            >
                <ProgressFill
                    ref={progressRef}
                    progress={progress}
                    variant={variant}
                />
                {milestones?.map(milestone => (
                    <Milestone
                        key={milestone.value}
                        position={`${milestone.value}%`}
                        label={milestone.label}
                        height={height}
                    />
                ))}
            </ProgressBarContainer>
            {showLabel && (
                <ProgressLabel
                    fontSize={fontSize}
                    variant={variant}
                    glowing={glowing}
                >
                    {progress}
                </ProgressLabel>
            )}
        </ProgressWrapper>
    );
};

Progress.propTypes = {
    progress: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['default', 'glass', 'gradient', 'premium']),
    height: PropTypes.string,
    borderRadius: PropTypes.string,
    showLabel: PropTypes.bool,
    fontSize: PropTypes.string,
    glowing: PropTypes.bool,
    milestones: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string
        })
    ),
    labelGap: PropTypes.string,
    className: PropTypes.string,
};

Progress.defaultProps = {
    variant: 'default',
    height: '8px',
    borderRadius: '12px',
    showLabel: false,
    fontSize: '0.875rem',
    glowing: false,
    labelGap: '12px',
};

export default Progress;
