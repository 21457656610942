// CharacteristicsPanel.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    AlertTriangle,
    RotateCcw,
    Info,
    Check,
    Thermometer,
    Cpu,
    Zap,
    Brain,
    Smile,
    Palette,
    Target,
    Eye,
    MessageCircle,
    Meh

} from 'lucide-react';
import { Button } from '../../../../../App/components/ui/button.jsx';
import { Alert, AlertDescription } from '../../../../../App/components/ui/alert.jsx';
import { Label } from '../../../../../App/components/ui/label.js';
import { trackEvent } from '../../../../../DeepAnalytics/analytics.js';
import './CharacteristicsPanel.css';

const AI_CHARACTERISTICS = {
    creativity: {
        name: 'Creativity',
        description: 'Controls how creative and innovative the responses are.',
        min: 0,
        max: 1,
        step: 0.01,
        icon: Palette,
        color: '#20b2aa',
    },
    formality: {
        name: 'Formality',
        description: 'Adjusts the tone from casual to formal.',
        min: 0,
        max: 1,
        step: 0.01,
        icon: Meh,
        color: '#4a90e2',
    },
    empathy: {
        name: 'Empathy',
        description: 'Controls emotional understanding and response.',
        min: 0,
        max: 1,
        step: 0.01,
        icon: Smile,
        color: '#e6a23c',
    },
    precision: {
        name: 'Precision',
        description: 'Controls accuracy and specificity of responses.',
        min: 0,
        max: 1,
        step: 0.01,
        icon: Target,
        color: '#67c23a',
    },
    temperature: {
        name: 'Temperature',
        description: 'Controls randomness: Lower values make responses more focused.',
        min: 0,
        max: 2,
        step: 0.1,
        icon: Thermometer,
        color: '#f56c6c',
    },
    complexity: {
        name: 'Complexity',
        description: 'Adjusts the depth and detail level.',
        min: 0,
        max: 1,
        step: 0.01,
        icon: Brain,
        color: '#9013fe',
    },
};

const TECHNICAL_SETTINGS = [
    {
        key: 'maxTokens',
        label: 'Max Length',
        icon: Cpu,
        description: 'Maximum length of generated responses.',
        min: 100,
        max: 4000,
        step: 100,
        defaultValue: 2000,
        displayValue: (val) => `${val} tokens`,
    },
    {
        key: 'topP',
        label: 'Top P',
        icon: Eye,
        description: 'Controls diversity of responses.',
        min: 0,
        max: 1,
        step: 0.05,
        defaultValue: 0.9,
    },
    {
        key: 'presencePenalty',
        label: 'Presence Penalty',
        icon: Zap,
        description: 'Penalizes topic repetition.',
        min: 0,
        max: 2,
        step: 0.1,
        defaultValue: 0.5,
    },
];

function CharacteristicsPanel({ onChange, initialSettings }) {
    const [settings, setSettings] = useState(() => ({
        ...Object.keys(AI_CHARACTERISTICS).reduce((acc, key) => {
            acc[key] = 0.5;
            return acc;
        }, {}),
        maxTokens: 2000,
        topP: 0.9,
        presencePenalty: 0.5,
        streamResponse: true,
        enhancedContext: true,
        ...initialSettings,
    }));

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [activePreset, setActivePreset] = useState('custom');
    const [showTooltip, setShowTooltip] = useState(null);

    const presets = {
        professional: {
            name: 'Professional',
            settings: {
                creativity: 0.3,
                formality: 0.9,
                empathy: 0.6,
                precision: 0.8,
                temperature: 0.5,
                complexity: 0.7,
                maxTokens: 2000,
                topP: 0.7,
                presencePenalty: 0.3,
            },
        },
        creative: {
            name: 'Creative',
            settings: {
                creativity: 0.8,
                formality: 0.4,
                empathy: 0.7,
                precision: 0.5,
                temperature: 1.2,
                complexity: 0.6,
                maxTokens: 3000,
                topP: 0.9,
                presencePenalty: 0.7,
            },
        },
        balanced: {
            name: 'Balanced',
            settings: {
                creativity: 0.5,
                formality: 0.5,
                empathy: 0.5,
                precision: 0.5,
                temperature: 0.7,
                complexity: 0.5,
                maxTokens: 2000,
                topP: 0.8,
                presencePenalty: 0.5,
            },
        },
    };

    const handleSettingChange = (key, value) => {
        const newSettings = { ...settings, [key]: value };
        setSettings(newSettings);
        setActivePreset('custom');

        try {
            onChange(newSettings);
            trackEvent('AI Setting Changed', { setting: key, value });
            setSuccess('Settings updated successfully!');
            setTimeout(() => setSuccess(''), 2000);
        } catch (error) {
            setError('Failed to update settings');
            console.error('Settings update error:', error);
        }
    };

    const applyPreset = (presetName) => {
        const preset = presets[presetName];
        if (preset) {
            setSettings(preset.settings);
            onChange(preset.settings);
            setActivePreset(presetName);
            trackEvent('AI Preset Applied', { preset: presetName });
            setSuccess(`Applied ${preset.name} preset`);
            setTimeout(() => setSuccess(''), 2000);
        }
    };

    const resetSettings = () => {
        const defaultSettings = {
            ...Object.keys(AI_CHARACTERISTICS).reduce((acc, key) => {
                acc[key] = 0.5;
                return acc;
            }, {}),
            maxTokens: 2000,
            topP: 0.9,
            presencePenalty: 0.5,
            streamResponse: true,
            enhancedContext: true,
        };
        setSettings(defaultSettings);
        onChange(defaultSettings);
        setActivePreset('balanced');
        trackEvent('AI Settings Reset');
        setSuccess('Settings reset to default');
        setTimeout(() => setSuccess(''), 2000);
    };

    return (
        <div className="slider-panel">
            {error && (
                <Alert variant="destructive" className="mb-4">
                    <AlertTriangle className="w-4 h-4 mr-2" />
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            {success && (
                <Alert variant="success" className="mb-4">
                    <Check className="w-4 h-4 mr-2" />
                    <AlertDescription>{success}</AlertDescription>
                </Alert>
            )}

            <div className="presets-section">
                <h3 className="section-title">AI Behavior Presets</h3>
                <div className="preset-buttons">
                    {Object.entries(presets).map(([key, preset]) => (
                        <Button
                            key={key}
                            variant={activePreset === key ? 'default' : 'outline'}
                            onClick={() => applyPreset(key)}
                            className={`preset-button ${activePreset === key ? 'active' : ''}`}
                        >
                            {preset.name}
                        </Button>
                    ))}
                </div>
            </div>

            <div className="sliders-container">
                <h3 className="section-title">Personality Settings</h3>
                {Object.entries(AI_CHARACTERISTICS).map(([key, config]) => (
                    <div
                        key={key}
                        className="slider-group"
                        onMouseEnter={() => setShowTooltip(key)}
                        onMouseLeave={() => setShowTooltip(null)}
                    >
                        <div className="slider-header">
                            <div className="slider-label">
                                <config.icon className="w-6 h-6" />
                                {config.name}
                            </div>
                            <span className="slider-value" style={{ color: config.color }}>
                {(settings[key] * 100).toFixed(0)}%
              </span>
                        </div>

                        <div className="slider-control">
                            <input
                                type="range"
                                min={config.min}
                                max={config.max}
                                step={config.step}
                                value={settings[key]}
                                onChange={(e) => handleSettingChange(key, parseFloat(e.target.value))}
                                className="range-slider"
                            />
                        </div>

                        {showTooltip === key && (
                            <div className="slider-tooltip">
                                <Info className="w-4 h-4" />
                                {config.description}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className="sliders-container">
                <h3 className="section-title">Technical Settings</h3>
                {TECHNICAL_SETTINGS.map((setting) => (
                    <div key={setting.key} className="slider-group">
                        <div className="slider-header">
                            <div className="slider-label">
                                <setting.icon className="w-6 h-6" />
                                {setting.label}
                            </div>
                            <span className="slider-value">
                {setting.displayValue
                    ? setting.displayValue(settings[setting.key])
                    : settings[setting.key].toFixed(1)}
              </span>
                        </div>

                        <div className="slider-control">
                            <input
                                type="range"
                                min={setting.min}
                                max={setting.max}
                                step={setting.step}
                                value={settings[setting.key]}
                                onChange={(e) => handleSettingChange(setting.key, parseFloat(e.target.value))}
                                className="range-slider"
                            />
                        </div>
                    </div>
                ))}
            </div>

            <div className="switches-container">
                <div className="switch-container">
                    <div className="switch-label">
                        <MessageCircle className="w-6 h-6" />
                        <Label>Stream Response</Label>
                    </div>
                    <div className="switch">
                        <input
                            type="checkbox"
                            checked={settings.streamResponse}
                            onChange={(e) => handleSettingChange('streamResponse', e.target.checked)}
                        />
                        <span className="slider"></span>
                    </div>
                </div>

                <div className="switch-container">
                    <div className="switch-label">
                        <Brain className="w-6 h-6" />
                        <Label>Enhanced Context</Label>
                    </div>
                    <div className="switch">
                        <input
                            type="checkbox"
                            checked={settings.enhancedContext}
                            onChange={(e) => handleSettingChange('enhancedContext', e.target.checked)}
                        />
                        <span className="slider"></span>
                    </div>
                </div>
            </div>

            <div className="panel-footer">
                <button className="reset-button" onClick={resetSettings}>
                    <RotateCcw className="w-5 h-5" />
                    Reset All Settings
                </button>
            </div>
        </div>
    );
}

CharacteristicsPanel.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialSettings: PropTypes.object,
};

export default CharacteristicsPanel;
