// src/utils/RightPanelUtils.js

import { MESSAGE_TYPES, RESPONSE_PROVIDERS } from '../constants/constants.js';

// Response Processing
export const formatAIResponse = (response, provider, timestamp) => ({
    id: `${provider.toLowerCase()}-${Date.now()}`,
    role: MESSAGE_TYPES.ASSISTANT,
    content: response.content,
    provider,
    model: response.model || 'Unknown Model',
    timestamp,
    metadata: {
        tokensUsed: response.usage?.total_tokens || 0,
        latency: response.latency || 0,
    }
});

export const createNewMessageGroup = (content, timestamp = new Date().toISOString()) => {
    const groupId = `msg-${Date.now()}`;

    return {
        id: groupId,
        timestamp,
        userMessage: {
            id: `user-${Date.now()}`,
            role: MESSAGE_TYPES.USER,
            content,
            timestamp,
            messageGroupId: groupId
        },
        responses: []
    };
};

// Message Groups Management
export const updateMessageGroup = (groups, groupId, update) => {
    const newGroups = new Map(groups);
    const group = newGroups.get(groupId);
    if (group) {
        newGroups.set(groupId, { ...group, ...update });
    }
    return newGroups;
};

export const getMessageGroupById = (groups, groupId) => {
    return groups.get(groupId);
};

// Response Grouping
export const groupResponsesByTimestamp = (responses) => {
    return responses.reduce((groups, response) => {
        const timestamp = response.timestamp?.split('T')[0];
        if (!timestamp) return groups;

        if (!groups[timestamp]) {
            groups[timestamp] = [];
        }
        groups[timestamp].push(response);
        return groups;
    }, {});
};

// Response Processing
export const processAIResponse = (response, timestamp = new Date().toISOString()) => {
    const responses = [];

    if (response.anthropic) {
        responses.push({
            id: `claude-${Date.now()}`,
            timestamp,
            role: MESSAGE_TYPES.ASSISTANT,
            content: response.anthropic.content,
            provider: RESPONSE_PROVIDERS.CLAUDE,
            model: response.anthropic.model,
            type: 'anthropic'
        });
    }

    if (response.openai) {
        responses.push({
            id: `openai-${Date.now()}`,
            timestamp,
            role: MESSAGE_TYPES.ASSISTANT,
            content: response.openai.content,
            provider: RESPONSE_PROVIDERS.OPENAI,
            model: response.openai.model,
            type: 'openai'
        });
    }

    return responses;
};

// Validation
export const validateResponseData = (response) => {
    const requiredFields = ['content', 'provider', 'timestamp'];
    return requiredFields.every(field => {
        if (!(field in response)) {
            console.error(`Missing required field in response: ${field}`);
            return false;
        }
        return true;
    });
};

export const validateMessageGroup = (group) => {
    if (!group.userMessage || !group.responses) {
        console.error('Invalid message group structure');
        return false;
    }
    return true;
};

// State Management
export const savePanelState = (key, state) => {
    try {
        localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
        console.error('Failed to save panel state:', error);
    }
};

export const loadPanelState = (key) => {
    try {
        const savedState = localStorage.getItem(key);
        return savedState ? JSON.parse(savedState) : null;
    } catch (error) {
        console.error('Failed to load panel state:', error);
        return null;
    }
};

export const validatePanelState = (state) => {
    return state && typeof state === 'object';
};

// Export functions for use in components
export const exportResponses = (responses, format = 'json') => {
    const data = responses.map(response => ({
        timestamp: response.timestamp,
        role: response.role,
        content: response.content,
        provider: response.provider,
        model: response.model
    }));

    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `responses_${new Date().toISOString()}.${format}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};