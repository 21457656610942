const Logo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        {/* Outer Decagon */}
        <polygon
            points="200.00,80.00 270.53,102.92 314.13,162.92 314.13,237.08 270.53,297.08 200.00,320.00 129.47,297.08 85.87,237.08 85.87,162.92 129.47,102.92"
            fill="none"
            stroke="#20B2AA"
            strokeWidth="2"
            className="logo-outer"
        />

        {/* Inner Decagon */}
        <polygon
            points="200.00,100.00 258.78,119.10 295.11,169.10 295.11,230.90 258.78,280.90 200.00,300.00 141.22,280.90 104.89,230.90 104.89,169.10 141.22,119.10"
            fill="#20B2AA"
            stroke="#FFFFFF"
            strokeWidth="1"
            className="logo-inner"
        />

        {/* Text and Shapes */}
        <text
            x="135"
            y="210"
            fontFamily="Orbitron, sans-serif"
            fontSize="28"
            fill="black"
            textAnchor="middle"
        >
            T
        </text>
        <polygon
            points="145,210 175,210 160,190"
            fill="black"
            stroke="white"
            strokeWidth="1"
        />
        <text
            x="225"
            y="210"
            fontFamily="Orbitron, sans-serif"
            fontSize="28"
            fill="black"
            textAnchor="middle"
            letterSpacing="0"
        >
            YTROM
        </text>
    </svg>
);

export default Logo;