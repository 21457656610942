import PropTypes from "prop-types";
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { Modal } from 'react-responsive-modal';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import vscDarkPlus from 'react-syntax-highlighter/dist/esm/styles/prism/vsc-dark-plus.js';
import remarkGfm from 'remark-gfm';
import {
    Copy,
    Settings,
    Code,
    Eye,
    Upload,
    MessageCircle,
    Save,
    RotateCcw,
    AlertTriangle,
    Maximize2,
    Minimize2,
    Download,
    Check,
    Type,
} from 'lucide-react';
import { Button } from '../../../../../App/components/ui/button.jsx';
import { Alert, AlertDescription } from '../../../../../App/components/ui/alert.jsx';
import { useApi } from '../../../../../Services/api/ApiContext.js';

import CodeMirror from '@uiw/react-codemirror';
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { languages } from '@codemirror/language-data';
import { dracula } from '@uiw/codemirror-theme-dracula';
import './InputPanel.css';

const MAX_CHAR_LIMIT = 20000;
const DEBOUNCE_DELAY = 300;
const AUTO_SAVE_DELAY = 1000;

// Define prop types outside the component
InputPanel.propTypes = {
    isLoading: PropTypes.bool,
    tokenBalance: PropTypes.number,
    isAIEnabled: PropTypes.bool,
};

function InputPanel({
                        isLoading = false,
                        tokenBalance = 0,
                        isAIEnabled = false
                    }) {
    // State management
    const [content, setContent] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showSettings, setShowSettings] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [saveStatus, setSaveStatus] = useState('');
    const [wordWrap, setWordWrap] = useState(true);
    const [theme, setTheme] = useState('dracula');
    const [useMarkdown, setUseMarkdown] = useState(false);

    const getTheme = () => {
        switch (theme) {
            case 'dark':
                return dracula; // You can import other themes from @uiw/codemirror-theme-*
            case 'light':
                return undefined; // default light theme
            default:
                return dracula;
        }
    };

    // Refs
    const lastSavedRef = useRef(content);

    // Get API context
    const apiContext = useApi();

    // Validation
    const isValidContent = typeof content === 'string' &&
        content.trim() !== '' &&
        content.length <= MAX_CHAR_LIMIT;

    // Auto-save functionality
    const autoSave = useCallback(
        debounce((value) => {
            if (value && value !== lastSavedRef.current) {
                localStorage.setItem('savedContent', value);
                lastSavedRef.current = value;
                setSaveStatus('Saved');
                setTimeout(() => setSaveStatus(''), 2000);
            }
        }, AUTO_SAVE_DELAY),
        []
    );

    // Content validation
    const validateContent = useCallback(
        debounce((value) => {
            setCharCount(value.length);
            if (value.length > MAX_CHAR_LIMIT) {
                setError(`Character limit exceeded (${value.length}/${MAX_CHAR_LIMIT})`);
            } else {
                setError('');
            }
            if (value !== lastSavedRef.current) {
                localStorage.setItem('contentModified', 'true');
            }
        }, DEBOUNCE_DELAY),
        []
    );

    // Content handlers
    const handleChange = (editor, data, value) => {
        setContent(value);
        validateContent(value);
        autoSave(value);
    };

    const handleTextareaChange = (e) => {
        const value = e.target.value;
        setContent(value);
        validateContent(value);
        autoSave(value);
    };

    // Submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidContent || isLoading) return;

        try {
            setError('');
            setSuccess('');

            const sanitizedContent = content.trim();
            await apiContext.onSubmit(sanitizedContent);

            setSuccess('Content submitted successfully!');
            setTimeout(() => setSuccess(''), 2000);
            setContent('');
            setCharCount(0);
        } catch (err) {
            setError(err.message || 'Failed to submit content');
            console.error('Submission error:', err);
        }
    };

    // File handlers
    const handleFileUpload = async (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        try {
            const text = await file.text();
            if (text.length > MAX_CHAR_LIMIT) {
                setError('File content exceeds character limit');
                return;
            }

            setContent(text);
            validateContent(text);
            setSuccess('File uploaded successfully!');
            setTimeout(() => setSuccess(''), 2000);
        } catch (err) {
            setError('Failed to read file');
            console.error('File upload error:', err);
        }
    };


    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(content);
            setSuccess('Content copied to clipboard!');
            setTimeout(() => setSuccess(''), 2000);
        } catch (err) {
            setError('Failed to copy content');
        }
    };

    const handleDownload = () => {
        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'document.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const resetContent = () => {
        if (
            content &&
            !window.confirm('Are you sure you want to clear the current content?')
        ) {
            return;
        }
        setContent('');
        setCharCount(0);
        setError('');
        lastSavedRef.current = '';
        localStorage.removeItem('savedContent');
    };

    // Toggle fullscreen
    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setIsFullscreen(true);
        } else {
            document.exitFullscreen();
            setIsFullscreen(false);
        }
    };

    // Load saved content on mount
    useEffect(() => {
        const savedContent = localStorage.getItem('savedContent');
        if (savedContent) {
            setContent(savedContent);
            setCharCount(savedContent.length);
            lastSavedRef.current = savedContent;
        }
    }, []);

    // Keyboard shortcuts
    useEffect(() => {
        const handleKeyDown = (e) => {
            if ((e.ctrlKey || e.metaKey) && !isLoading) {
                switch (e.key.toLowerCase()) {
                    case 'enter':
                        e.preventDefault();
                        if (isValidContent) handleSubmit(e);
                        break;
                    case 'p':
                        e.preventDefault();
                        setShowPreview(!showPreview);
                        break;
                    case 's':
                        e.preventDefault();
                        setShowSettings(true);
                        break;
                    case 'l':
                        e.preventDefault();
                        resetContent();
                        break;
                    case 'f':
                        e.preventDefault();
                        toggleFullscreen();
                        break;
                    default:
                        break;
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [showPreview, isLoading, isValidContent]);
    useEffect(() => {
        const container = document.querySelector('.editor-container');

        const handleScroll = () => {
            if (!container) return;

            const isScrollable = container.scrollHeight > container.clientHeight;
            const isAtTop = container.scrollTop <= 0;
            const isAtBottom =
                Math.ceil(container.scrollTop + container.clientHeight) >=
                container.scrollHeight;

            container.classList.toggle('can-scroll', isScrollable);
            container.classList.toggle('scroll-top', !isAtTop);
            container.classList.toggle('scroll-bottom', !isAtBottom);
        };

        if (container) {
            container.addEventListener('scroll', handleScroll);
            // Initial check
            handleScroll();

            // Handle content changes
            const resizeObserver = new ResizeObserver(handleScroll);
            resizeObserver.observe(container);

            return () => {
                container.removeEventListener('scroll', handleScroll);
                resizeObserver.disconnect();
            };
        }
    }, []);
    return (
        <div className={`input-panel ${isFullscreen ? 'fullscreen' : ''}`}>
            <div className="toolbar">
                <div className="toolbar-left">
                    <Button
                        variant="ghost"
                        onClick={() => setShowSettings(true)}
                        disabled={isLoading}
                        className="toolbar-button"
                    >
                        <Settings className="w-5 h-5"/>
                        <span>Settings</span>
                    </Button>
                    <Button
                        variant="ghost"
                        onClick={() => setUseMarkdown(!useMarkdown)}
                        disabled={isLoading}
                        className="toolbar-button"
                    >
                        {useMarkdown ? (
                            <Type className="w-5 h-5"/>
                        ) : (
                            <Code className="w-5 h-5"/>
                        )}
                        <span>{useMarkdown ? 'Plain Text' : 'Markdown'}</span>
                    </Button>
                    {useMarkdown && (
                        <Button
                            variant="ghost"
                            onClick={() => setShowPreview(!showPreview)}
                            disabled={isLoading}
                            className="toolbar-button"
                        >
                            {showPreview ? (
                                <Code className="w-5 h-5"/>
                            ) : (
                                <Eye className="w-5 h-5"/>
                            )}
                            <span>{showPreview ? 'Edit' : 'Preview'}</span>
                        </Button>
                    )}
                </div>
                <div className="toolbar-right">
                    <Button
                        variant="ghost"
                        onClick={handleCopy}
                        disabled={!content || isLoading}
                        className="toolbar-button"
                    >
                        <Copy className="w-5 h-5"/>
                        <span>Copy</span>
                    </Button>
                    <Button
                        variant="ghost"
                        onClick={handleDownload}
                        disabled={!content || isLoading}
                        className="toolbar-button"
                    >
                        <Download className="w-5 h-5"/>
                        <span>Download</span>
                    </Button>
                    <Button
                        variant="ghost"
                        onClick={toggleFullscreen}
                        className="toolbar-button"
                    >
                        {isFullscreen ? (
                            <Minimize2 className="w-5 h-5"/>
                        ) : (
                            <Maximize2 className="w-5 h-5"/>
                        )}
                    </Button>
                </div>
            </div>

            {error && (
                <Alert variant="destructive" className="mb-4 alert alert-error">
                    <AlertTriangle className="w-5 h-5 alert-icon"/>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            {success && (
                <Alert variant="success" className="mb-4 alert alert-success">
                    <Check className="w-5 h-5 alert-icon"/>
                    <AlertDescription>{success}</AlertDescription>
                </Alert>
            )}

            <div className="editor-container">
                {useMarkdown ? (
                    showPreview ? (
                        <div className="preview-mode">
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    code({inline, className, children, ...props}) {
                                        const match = /language-(\w+)/.exec(className || '');
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                style={vscDarkPlus}
                                                language={match[1]}
                                                PreTag="div"
                                                {...props}
                                            >
                                                {String(children).replace(/\n$/, '')}
                                            </SyntaxHighlighter>
                                        ) : (
                                            <code className={className} {...props}>
                                                {children}
                                            </code>
                                        );
                                    }
                                }}
                            >
                                {content}
                            </ReactMarkdown>
                        </div>
                    ) : (
                        <CodeMirror
                            value={content}
                            height="100%"
                            theme={getTheme()}
                            extensions={[
                                markdown({
                                    base: markdownLanguage,
                                    codeLanguages: languages
                                })
                            ]}
                            onChange={(value) => handleChange(null, null, value)}
                            readOnly={isLoading}
                            placeholder="Start typing here... (Markdown supported)"
                            basicSetup={{
                                lineNumbers: true,
                                bracketMatching: true,
                                closeBrackets: true,
                                lineWrapping: wordWrap
                            }}
                        />
                    )
                ) : (
                    <textarea
                        className="textarea-input"
                        value={content}
                        onChange={handleTextareaChange}
                        placeholder="Start typing here..."
                        readOnly={isLoading}
                    />
                )}
            </div>

            <div className="status-bar">
                <div className="status-left">
          <span
              className={`char-count ${
                  charCount > MAX_CHAR_LIMIT ? 'exceeds' : ''
              }`}
          >
            {charCount} / {MAX_CHAR_LIMIT} characters
          </span>
                    {isAIEnabled && (
                        <span className="token-balance">
              Token Balance: {tokenBalance}
            </span>
                    )}
                </div>
                {saveStatus && (
                    <div className="save-status">
                        <Save className="w-5 h-5"/>
                        {saveStatus}
                    </div>
                )}
            </div>

            <div className="action-bar">
                <div className="action-left">
                    <Button
                        variant="ghost"
                        onClick={resetContent}
                        disabled={isLoading || !content}
                        className="action-button"
                    >
                        <RotateCcw className="w-5 h-5"/>
                        <span>Clear</span>
                    </Button>
                    <label className="file-upload-label">
                        <Button
                            variant="ghost"
                            disabled={isLoading}
                            className="action-button"
                        >
                            <Upload className="w-5 h-5"/>
                            <span>Upload File</span>
                        </Button>
                        <input
                            type="file"
                            onChange={handleFileUpload}
                            accept=".txt,.md,.js,.py,.json"
                            disabled={isLoading}
                        />
                    </label>
                </div>
                <Button
                    onClick={handleSubmit}
                    disabled={!isValidContent || isLoading}
                    className="submit-button"
                >
                    {isLoading ? (
                        <div className="loading-spinner"/>
                    ) : (
                        <MessageCircle className="w-5 h-5"/>
                    )}
                    <span>{isAIEnabled ? 'Submit to AI' : 'Save'}</span>
                </Button>
            </div>

            <Modal
                open={showSettings}
                onClose={() => setShowSettings(false)}
                center
                classNames={{
                    modal: 'settings-modal',
                }}
                styles={{
                    modal: {
                        background: '#1a1a1a',
                    },
                }}
            >
                <h2>Editor Settings</h2>
                <div className="settings-content">
                    {useMarkdown && (
                        <div className="setting-item">
                            <span>Word Wrap</span>
                            <Button
                                variant="ghost"
                                onClick={() => setWordWrap(!wordWrap)}
                                className="setting-toggle"
                            >
                                {wordWrap ? 'On' : 'Off'}
                            </Button>
                        </div>
                    )}
                    {useMarkdown && (
                        <div className="setting-item">
                            <span>Theme</span>
                            <select
                                className="setting-select"
                                value={theme}
                                onChange={(e) => setTheme(e.target.value)}
                            >
                                <option value="dracula">Dracula</option>
                                <option value="dark">Dark</option>
                                <option value="light">Light</option>
                            </select>
                        </div>
                    )}
                    <div className="setting-item">
                        <span>Use Markdown</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={useMarkdown}
                                onChange={() => setUseMarkdown(!useMarkdown)}
                            />
                            <span className="toggle-slider"></span>
                        </label>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

InputPanel.propTypes = {
    isLoading: PropTypes.bool,
    tokenBalance: PropTypes.number,
    isAIEnabled: PropTypes.bool,
};


export default InputPanel;
