import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App/App.js';
import { ApiProvider } from './Services/api/ApiContext.js';
import { PanelProvider } from './App/context/PanelContext.js';
import './App/components/styles/App.css';


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ApiProvider>
            <PanelProvider>
                <App />
            </PanelProvider>
        </ApiProvider>
    </React.StrictMode>
);
