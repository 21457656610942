import React, { useMemo } from 'react';
import { MessageSquarePlus, AlertTriangle, Clock, Bot, Copy, Share2, Trophy } from 'lucide-react';
import { Button } from '../../../../../App/components/ui/button.jsx';
import { Alert, AlertDescription } from '../../../../../App/components/ui/alert.jsx';
import Card, { CardHeader, CardContent, CardFooter } from '../../../../../App/components/ui/Card.js';
import { RadioGroupCards } from '../../../../../App/components/ui/radio-group.js';
import { useApi } from '../../../../../Services/api/ApiContext.js';
import './ResponsesPanel.css';

const ResponsesPanel = () => {
    const {
        responses,
        error,
        onWinnerSelected: onWinnerSelectedApi,
        onSubmit: handleNewPrompt,
        isLoading
    } = useApi();

    const groupedResponses = useMemo(() => {
        const groups = {};
        const sortedResponses = [...responses].sort((a, b) =>
            new Date(a.timestamp) - new Date(b.timestamp)
        );

        sortedResponses.forEach(response => {
            const timestamp = response.timestamp?.split('T')[0];
            if (!timestamp) return;

            if (!groups[timestamp]) {
                groups[timestamp] = {
                    userMessage: null,
                    assistantResponses: [],
                    timestamp
                };
            }

            if (response.role === 'user') {
                groups[timestamp].userMessage = response;
            } else if (response.role === 'assistant') {
                groups[timestamp].assistantResponses.push(response);
            }
        });

        return groups;
    }, [responses]);

    const winners = useMemo(() => {
        const winnerMap = {};
        Object.entries(groupedResponses).forEach(([timestamp, group]) => {
            const winner = group.assistantResponses.find(r => r.isWinner);
            if (winner) {
                winnerMap[timestamp] = winner.type;
            }
        });
        return winnerMap;
    }, [groupedResponses]);

    const handleCopy = async (content) => {
        try {
            await navigator.clipboard.writeText(content);
        } catch (err) {
            console.error('Copy failed:', err);
        }
    };

    const handleShare = (response) => {
        console.log('Sharing response:', response);
    };

    const handleWinnerSelection = (timestamp, value) => {
        console.log('Selecting winner:', { timestamp, value });
        onWinnerSelectedApi?.(timestamp, value);
    };

    if (Object.keys(groupedResponses).length === 0) {
        return (
            <div className="empty-state">
                <Bot className="empty-icon" />
                <p className="empty-text">No responses yet</p>
                <Button onClick={handleNewPrompt} className="new-prompt-button">
                    <MessageSquarePlus className="icon" />
                    New Prompt
                </Button>
            </div>
        );
    }

    const getProviderName = (response) => {
        const displayNames = {
            'anthropic': 'Caligula',
            'openai': 'Tiberius'
        };
        return displayNames[response.type] || response.provider;
    };

    return (
        <div className="responses-panel">
            {error && (
                <Alert variant="destructive" className="error-alert">
                    <AlertTriangle className="icon" />
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            <div className="response-groups">
                {Object.entries(groupedResponses).map(([timestamp, group]) => {
                    if (!group.assistantResponses.length) return null;

                    return (
                        // Remove the boolean props that were causing warnings
                        <Card
                            key={`group-${timestamp}`}
                            className="comparison-card"
                            // Only pass non-boolean DOM attributes
                            data-elevated={false}
                            data-rounded={false}
                            data-interactive={false}
                            data-loading={false}
                            data-glow={false}
                        >
                            <CardHeader className="comparison-header">
                                <div className="header-content">
                                    <div className="timestamp">
                                        <Clock className="icon" />
                                        <span>{new Date(timestamp).toLocaleDateString()}</span>
                                    </div>
                                    {group.userMessage && (
                                        <div className="user-message">
                                            &ldquo;{group.userMessage.content}&rdquo;
                                        </div>
                                    )}
                                    {winners[timestamp] && (
                                        <div className="winner-badge">
                                            <Trophy className="icon" />
                                            <span>Winner: {getProviderName({ type: winners[timestamp] })}</span>
                                        </div>
                                    )}
                                </div>
                            </CardHeader>

                            <CardContent className="comparison-content">
                                <div className="response-grid">
                                    {group.assistantResponses.map((response) => (
                                        <div
                                            key={`response-${response.id || response.timestamp}-${response.type}`}
                                            className={`response-card ${
                                                winners[timestamp] === response.type
                                                    ? 'winner'
                                                    : ''
                                            }`}
                                        >
                                            <div className="response-header">
                                                <div className="model-label">
                                                    <Bot className="icon"/>
                                                    <span className="provider-name">
                                                        {getProviderName(response)}
                                                    </span>
                                                </div>
                                                <div className="action-buttons">
                                                    <Button
                                                        variant="ghost"
                                                        onClick={() => handleCopy(response.content)}
                                                        className="action-button"
                                                        aria-label="Copy response"
                                                    >
                                                        <Copy className="icon" />
                                                    </Button>
                                                    <Button
                                                        variant="ghost"
                                                        onClick={() => handleShare(response)}
                                                        className="action-button"
                                                        aria-label="Share response"
                                                    >
                                                        <Share2 className="icon" />
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className="response-content">
                                                <div className="content-marker"/>
                                                <div className="content-text">
                                                    {response.content}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </CardContent>

                            <CardFooter className="comparison-footer">
                                <div className="winner-selection">
                                    <div className="selection-header">
                                        <Trophy className="trophy-icon"/>
                                        <span>Select Winner</span>
                                    </div>
                                    <RadioGroupCards
                                        options={group.assistantResponses.map(response => ({
                                            key: `option-${response.type}-${response.id}`,
                                            label: getProviderName(response),
                                            value: response.type
                                        }))}
                                        value={winners[timestamp] || ""}
                                        onValueChange={(value) => handleWinnerSelection(timestamp, value)}
                                    />
                                </div>
                            </CardFooter>
                        </Card>
                    );
                })}
            </div>

            <div className="prompt-actions">
                <Button
                    onClick={handleNewPrompt}
                    className="new-prompt-button"
                    disabled={isLoading}
                >
                    <MessageSquarePlus className="icon" />
                    New Prompt
                </Button>
            </div>
        </div>
    );
};

export default ResponsesPanel;