// src/components/RightPanel/components/AnalyticsPanel/AnalyticsPanel.js

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    TrendingUp,
    TrendingDown,
    Clock,
    Activity,
    Download,
    RefreshCw,
    AlertTriangle,
    Zap,
    Brain,
    Settings,
    Save,
    ChevronDown,
    ChevronUp,
} from 'lucide-react';
import {
    LineChart,
    Line,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    Area,
    AreaChart,
    RadialBarChart,
    RadialBar,
} from 'recharts';
import { Button } from '../../../../../App/components/ui/button.jsx';
import { Alert, AlertDescription } from '../../../../../App/components/ui/alert.jsx';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../../../../App/components/ui/select.js';

import Progress from '../../../../../App/components/ui/progress.js';
import { format } from 'date-fns';
import {
    calculateMetrics,
    transformChartData,
    getInsights,
    TIME_RANGES,
    trackEvent,
} from '../../../../../DeepAnalytics/analytics.js';
import './AnalyticsPanel.css';

const COLORS = {
    primary: '#20b2aa',
    secondary: '#4a90e2',
    success: '#67c23a',
    warning: '#e6a23c',
    error: '#f56c6c',
    info: '#909399',
    purple: '#9013fe',
    pink: '#e83e8c',
    indigo: '#6610f2',
    teal: '#20c997',
};

const CHART_TYPES = {
    LINE: 'line',
    BAR: 'bar',
    PIE: 'pie',
    AREA: 'area',
    RADIAL: 'radial',
};

function AnalyticsPanel({ data, onRefresh, onExport, isLoading }) {
    // State management
    const [timeRange, setTimeRange] = useState('7d');
    const [activeMetric, setActiveMetric] = useState('usage');
    const [error] = useState('');
    const [chartType, setChartType] = useState(CHART_TYPES.LINE);
    const [showRealtime] = useState(false);
    const [filters, setFilters] = useState({
        models: [],
        types: [],
        success: 'all',
    });
    const [expandedSections, setExpandedSections] = useState({
        kpis: true,
        charts: true,
        insights: true,
        details: true,
    });

    // Memoized calculations
    const metrics = useMemo(() => calculateMetrics(data, timeRange), [data, timeRange]);
    const chartData = useMemo(() => transformChartData(metrics), [metrics]);
    const insights = useMemo(() => getInsights(metrics), [metrics]);

    // Realtime updates
    useEffect(() => {
        let interval;
        if (showRealtime) {
            interval = setInterval(onRefresh, 30000); // 30-second refresh
        }
        return () => clearInterval(interval);
    }, [showRealtime, onRefresh]);

    // Track view analytics
    useEffect(() => {
        trackEvent('analytics_panel_view', {
            timeRange,
            activeMetric,
            chartType,
        });
    }, [timeRange, activeMetric, chartType]);

    // Handle section expand/collapse
    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    const KPICards = () => (
        <div className="kpi-grid">
            <div className="kpi-card">
                <div className="flex items-center justify-between">
                    <div className="kpi-icon">
                        <Activity className="w-6 h-6 text-white" />
                    </div>
                    <div className="kpi-trend positive">
                        <TrendingUp className="w-4 h-4" />
                        <span>+12%</span>
                    </div>
                </div>
                <div className="kpi-value">{metrics?.totalRequests.toLocaleString()}</div>
                <div className="kpi-label">Total Requests</div>
            </div>

            <div className="kpi-card">
                <div className="flex items-center justify-between">
                    <div className="kpi-icon">
                        <Brain className="w-6 h-6 text-white" />
                    </div>
                    <div className="kpi-trend positive">
                        <TrendingUp className="w-4 h-4" />
                        <span>+2.5%</span>
                    </div>
                </div>
                <div className="kpi-value">{metrics?.successRate.toFixed(1)}%</div>
                <div className="kpi-label">Success Rate</div>
            </div>

            <div className="kpi-card">
                <div className="flex items-center justify-between">
                    <div className="kpi-icon">
                        <Clock className="w-6 h-6 text-white" />
                    </div>
                    <div className="kpi-trend negative">
                        <TrendingDown className="w-4 h-4" />
                        <span>-8%</span>
                    </div>
                </div>
                <div className="kpi-value">{metrics?.averageResponseTime.toFixed(2)}ms</div>
                <div className="kpi-label">Avg Response Time</div>
            </div>

            <div className="kpi-card">
                <div className="flex items-center justify-between">
                    <div className="kpi-icon">
                        <Zap className="w-6 h-6 text-white" />
                    </div>
                    <div className="kpi-trend negative">
                        <TrendingDown className="w-4 h-4" />
                        <span>-5%</span>
                    </div>
                </div>
                <div className="kpi-value">{metrics?.totalTokens.toLocaleString()}</div>
                <div className="kpi-label">Total Tokens</div>
            </div>
        </div>
    );

    const ChartSection = () => {
        const renderChart = () => {
            switch (chartType) {
                case CHART_TYPES.LINE:
                    return (
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={chartData.dailyUsage}>
                                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={(date) => format(new Date(date), 'MMM d')}
                                    tick={{ fill: 'var(--muted-color)' }}
                                />
                                <YAxis tick={{ fill: 'var(--muted-color)' }} />
                                <Tooltip
                                    contentStyle={{
                                        backgroundColor: 'var(--card-background)',
                                        border: 'none',
                                        borderRadius: '8px',
                                        color: 'var(--foreground-color)',
                                    }}
                                    labelStyle={{ color: 'var(--foreground-color)' }}
                                    itemStyle={{ color: 'var(--foreground-color)' }}
                                />
                                <Legend
                                    wrapperStyle={{ color: 'var(--foreground-color)' }}
                                    iconType="circle"
                                />
                                <Line
                                    type="monotone"
                                    dataKey="requests"
                                    stroke={COLORS.primary}
                                    strokeWidth={2}
                                    dot={{ r: 3 }}
                                    activeDot={{ r: 6 }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    );

                case CHART_TYPES.AREA:
                    return (
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart data={chartData.dailyUsage}>
                                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={(date) => format(new Date(date), 'MMM d')}
                                    tick={{ fill: 'var(--muted-color)' }}
                                />
                                <YAxis tick={{ fill: 'var(--muted-color)' }} />
                                <Tooltip
                                    contentStyle={{
                                        backgroundColor: 'var(--card-background)',
                                        border: 'none',
                                        borderRadius: '8px',
                                        color: 'var(--foreground-color)',
                                    }}
                                    labelStyle={{ color: 'var(--foreground-color)' }}
                                    itemStyle={{ color: 'var(--foreground-color)' }}
                                />
                                <Area
                                    type="monotone"
                                    dataKey="requests"
                                    stroke={COLORS.primary}
                                    fillOpacity={0.5}
                                    fill={`url(#colorGradient)`}
                                />
                                <defs>
                                    <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor={COLORS.primary} stopOpacity={0.8} />
                                        <stop offset="95%" stopColor={COLORS.primary} stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                            </AreaChart>
                        </ResponsiveContainer>
                    );

                case CHART_TYPES.PIE:
                    return (
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                                <Pie
                                    data={chartData.modelUsage}
                                    innerRadius={80}
                                    outerRadius={120}
                                    paddingAngle={5}
                                    dataKey="value"
                                    label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                                >
                                    {chartData.modelUsage.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={Object.values(COLORS)[index % Object.values(COLORS).length]}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip
                                    contentStyle={{
                                        backgroundColor: 'var(--card-background)',
                                        border: 'none',
                                        borderRadius: '8px',
                                        color: 'var(--foreground-color)',
                                    }}
                                    labelStyle={{ color: 'var(--foreground-color)' }}
                                    itemStyle={{ color: 'var(--foreground-color)' }}
                                />
                                <Legend
                                    wrapperStyle={{ color: 'var(--foreground-color)' }}
                                    iconType="circle"
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    );

                default:
                    return (
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={chartData.hourlyUsage}>
                                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                <XAxis dataKey="hour" tick={{ fill: 'var(--muted-color)' }} />
                                <YAxis tick={{ fill: 'var(--muted-color)' }} />
                                <Tooltip
                                    contentStyle={{
                                        backgroundColor: 'var(--card-background)',
                                        border: 'none',
                                        borderRadius: '8px',
                                        color: 'var(--foreground-color)',
                                    }}
                                    labelStyle={{ color: 'var(--foreground-color)' }}
                                    itemStyle={{ color: 'var(--foreground-color)' }}
                                />
                                <Bar
                                    dataKey="requests"
                                    fill={COLORS.secondary}
                                    radius={[4, 4, 0, 0]}
                                />
                                <Legend
                                    wrapperStyle={{ color: 'var(--foreground-color)' }}
                                    iconType="circle"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    );
            }
        };

        return (
            <div className="chart-card">
                <div className="chart-header">
                    <div>
                        <h3 className="chart-title">Analytics Overview</h3>
                        <p className="chart-subtitle">Visualization of usage patterns and trends</p>
                    </div>
                    <div className="controls">
                        <Select value={chartType} onValueChange={setChartType}>
                            <SelectTrigger className="select-trigger w-40">
                                <SelectValue placeholder="Chart Type" />
                            </SelectTrigger>
                            <SelectContent className="select-content">
                                <SelectItem value={CHART_TYPES.LINE}>Line Chart</SelectItem>
                                <SelectItem value={CHART_TYPES.BAR}>Bar Chart</SelectItem>
                                <SelectItem value={CHART_TYPES.PIE}>Pie Chart</SelectItem>
                                <SelectItem value={CHART_TYPES.AREA}>Area Chart</SelectItem>
                            </SelectContent>
                        </Select>
                        <Select value={timeRange} onValueChange={setTimeRange}>
                            <SelectTrigger className="select-trigger w-40">
                                <SelectValue placeholder="Time Range" />
                            </SelectTrigger>
                            <SelectContent className="select-content">
                                {Object.entries(TIME_RANGES).map(([key, { label }]) => (
                                    <SelectItem key={key} value={key}>
                                        {label}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                {isLoading ? (
                    <div className="loading-overlay">
                        <RefreshCw className="spinner" />
                    </div>
                ) : (
                    renderChart()
                )}
            </div>
        );
    };

    const InsightsSection = () => (
        <div className="kpi-grid">
            <div className="kpi-card">
                <div className="flex items-center justify-between">
                    <div className="kpi-icon">
                        <Clock className="w-6 h-6 text-white" />
                    </div>
                </div>
                <div className="kpi-value">{insights.peakUsageTime}</div>
                <div className="kpi-label">Peak Usage Time</div>
            </div>

            <div className="kpi-card">
                <div className="flex items-center justify-between">
                    <div className="kpi-icon">
                        <Brain className="w-6 h-6 text-white" />
                    </div>
                </div>
                <div className="kpi-value">{insights.popularModel.name}</div>
                <div className="kpi-label">Popular Model</div>
            </div>

            <div className="kpi-card">
                <div className="flex items-center justify-between">
                    <div className="kpi-icon">
                        <Activity className="w-6 h-6 text-white" />
                    </div>
                </div>
                <div className="kpi-value">{insights.averageResponseTime}ms</div>
                <div className="kpi-label">Avg Response Time</div>
            </div>
        </div>
    );

    const DetailedMetrics = () => (
        <div className="chart-card">
            <div className="chart-header">
                <h3 className="chart-title">Detailed Metrics</h3>
                <p className="chart-subtitle">Comprehensive analysis of all tracked metrics</p>
            </div>
            <div className="chart-content">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-4">
                        <h4 className="text-lg font-medium">Model Distribution</h4>
                        {Object.entries(metrics.modelUsage).map(([model, count]) => (
                            <div key={model} className="flex items-center justify-between">
                                <span className="text-sm">{model}</span>
                                <div className="flex items-center">
                                    <Progress
                                        value={(count / metrics.totalRequests) * 100}
                                        className="w-32 mr-2"
                                    />
                                    <span className="text-sm text-muted-foreground">
                    {((count / metrics.totalRequests) * 100).toFixed(1)}%
                  </span>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="space-y-4">
                        <h4 className="text-lg font-medium">Cost Analysis</h4>
                        <ResponsiveContainer width="100%" height={200}>
                            <RadialBarChart
                                innerRadius="30%"
                                outerRadius="100%"
                                data={Object.entries(metrics.costBreakdown)
                                    .filter(([key]) => key !== 'total')
                                    .map(([model, cost]) => ({
                                        name: model,
                                        cost: cost,
                                        fill: COLORS[Object.keys(COLORS)[
                                            Math.floor(Math.random() * Object.keys(COLORS).length)
                                            ]],
                                    }))}
                            >
                                <RadialBar
                                    minAngle={15}
                                    background
                                    dataKey="cost"
                                />
                                <Legend
                                    iconSize={10}
                                    layout="vertical"
                                    verticalAlign="middle"
                                    align="right"
                                    wrapperStyle={{ color: 'var(--foreground-color)' }}
                                />
                                <Tooltip
                                    contentStyle={{
                                        backgroundColor: 'var(--card-background)',
                                        border: 'none',
                                        borderRadius: '8px',
                                        color: 'var(--foreground-color)',
                                    }}
                                    labelStyle={{ color: 'var(--foreground-color)' }}
                                    itemStyle={{ color: 'var(--foreground-color)' }}
                                />
                            </RadialBarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            <div className="chart-footer flex justify-between">
                <Button variant="outline" size="sm" onClick={onExport}>
                    <Download className="w-4 h-4 mr-2" />
                    Export Detailed Report
                </Button>
                <Button variant="outline" size="sm" onClick={() => setActiveMetric('custom')}>
                    <Settings className="w-4 h-4 mr-2" />
                    Customize Metrics
                </Button>
            </div>
        </div>
    );

    return (
        <div className="analytics-panel">
            {error && (
                <Alert variant="destructive" className="mb-6">
                    <AlertTriangle className="w-4 h-4 mr-2" />
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            <div className="controls-bar">
                <h2 className="text-3xl font-bold">Analytics Dashboard</h2>
                <div className="action-buttons">
                    <Button variant="outline" onClick={onRefresh} disabled={isLoading}>
                        <RefreshCw className={`w-4 h-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
                        Refresh
                    </Button>
                    <Button variant="default" onClick={onExport}>
                        <Save className="w-4 h-4 mr-2" />
                        Save Report
                    </Button>
                </div>
            </div>

            {/* Filters Bar */}
            <div className="filters-bar">
                <div className="filter-group">
                    <label htmlFor="model-filter">Model:</label>
                    <select
                        id="model-filter"
                        value={filters.models}
                        onChange={(e) => setFilters({ ...filters, models: [e.target.value] })}
                    >
                        <option value="">All Models</option>
                        {/* Assuming models list is available */}
                        {Object.keys(metrics.modelUsage).map((model) => (
                            <option key={model} value={model}>
                                {model}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="filter-group">
                    <label htmlFor="type-filter">Type:</label>
                    <select
                        id="type-filter"
                        value={filters.types}
                        onChange={(e) => setFilters({ ...filters, types: [e.target.value] })}
                    >
                        <option value="">All Types</option>
                        {/* Assuming types list is available */}
                        <option value="text">Text</option>
                        <option value="image">Image</option>
                        {/* Add more types as needed */}
                    </select>
                </div>
                <div className="filter-group">
                    <label htmlFor="success-filter">Status:</label>
                    <select
                        id="success-filter"
                        value={filters.success}
                        onChange={(e) => setFilters({ ...filters, success: e.target.value })}
                    >
                        <option value="all">All</option>
                        <option value="true">Success</option>
                        <option value="false">Failure</option>
                    </select>
                </div>
            </div>

            <div className="analytics-content">
                {/* Collapsible Sections */}
                <section>
                    <div className="chart-header">
                        <h3 className="chart-title">Key Performance Indicators</h3>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => toggleSection('kpis')}
                        >
                            {expandedSections.kpis ? (
                                <ChevronUp className="w-5 h-5" />
                            ) : (
                                <ChevronDown className="w-5 h-5" />
                            )}
                        </Button>
                    </div>
                    {expandedSections.kpis && <KPICards />}
                </section>

                <section>
                    <div className="chart-header">
                        <h3 className="chart-title">Charts</h3>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => toggleSection('charts')}
                        >
                            {expandedSections.charts ? (
                                <ChevronUp className="w-5 h-5" />
                            ) : (
                                <ChevronDown className="w-5 h-5" />
                            )}
                        </Button>
                    </div>
                    {expandedSections.charts && <ChartSection />}
                </section>

                <section>
                    <div className="chart-header">
                        <h3 className="chart-title">Insights</h3>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => toggleSection('insights')}
                        >
                            {expandedSections.insights ? (
                                <ChevronUp className="w-5 h-5" />
                            ) : (
                                <ChevronDown className="w-5 h-5" />
                            )}
                        </Button>
                    </div>
                    {expandedSections.insights && <InsightsSection />}
                </section>

                <section>
                    <div className="chart-header">
                        <h3 className="chart-title">Detailed Metrics</h3>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => toggleSection('details')}
                        >
                            {expandedSections.details ? (
                                <ChevronUp className="w-5 h-5" />
                            ) : (
                                <ChevronDown className="w-5 h-5" />
                            )}
                        </Button>
                    </div>
                    {expandedSections.details && <DetailedMetrics />}
                </section>
            </div>

            {isLoading && (
                <div className="loading-overlay">
                    <RefreshCw className="spinner" />
                </div>
            )}
        </div>
    );
}

AnalyticsPanel.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            timestamp: PropTypes.string.isRequired,
            model: PropTypes.string.isRequired,
            tokens: PropTypes.number.isRequired,
            responseTime: PropTypes.number.isRequired,
            success: PropTypes.bool.isRequired,
            type: PropTypes.string.isRequired,
            cost: PropTypes.number.isRequired,
            rating: PropTypes.number,
        })
    ),
    onRefresh: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

AnalyticsPanel.defaultProps = {
    data: [],
    isLoading: false,
};

export default AnalyticsPanel;
