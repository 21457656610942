// DeepAnalytics/analytics.js
import axios from 'axios';
import { format, subDays } from 'date-fns';

const ANALYTICS_BASE_URL = process.env.REACT_APP_ANALYTICS_URL || 'http://localhost:8080/api/analytics';

// Constants
export const TIME_RANGES = {
    '24h': { label: 'Last 24 Hours', days: 1 },
    '7d': { label: 'Last 7 Days', days: 7 },
    '30d': { label: 'Last 30 Days', days: 30 },
    '90d': { label: 'Last 90 Days', days: 90 }
};

// Mock analytics for development
const mockAnalytics = {
    events: [],
    track: (eventName, properties) => {
        console.log('Development Analytics Event:', { eventName, properties });
        mockAnalytics.events.push({ eventName, properties, timestamp: new Date() });
    }
};

// Analytics instance configuration
const analyticsInstance = axios.create({
    baseURL: ANALYTICS_BASE_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Error handling middleware
analyticsInstance.interceptors.response.use(
    response => response,
    error => {
        console.warn('Analytics error:', error);
        return Promise.resolve({ data: { success: false, error: error.message } });
    }
);

/**
 * Calculate metrics from analytics data
 * @param {Array} data - Analytics data
 * @param {string} timeRange - Time range key
 * @returns {Object} Calculated metrics
 */
export const calculateMetrics = (data, timeRange) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
        return {
            totalRequests: 0,
            totalTokens: 0,
            averageResponseTime: 0,
            successRate: 0,
            modelUsage: {},
            topPromptTypes: {},
            dailyUsage: {},
            averageRating: 0,
            costBreakdown: { total: 0 },
            peakHours: {}
        };
    }

    const endDate = new Date();
    const startDate = subDays(endDate, TIME_RANGES[timeRange].days);
    const filteredData = data.filter(item => {
        const date = new Date(item.timestamp);
        return date >= startDate && date <= endDate;
    });

    return {
        totalRequests: filteredData.length,
        totalTokens: filteredData.reduce((acc, item) => acc + (item.tokens || 0), 0),
        averageResponseTime: filteredData.length > 0
            ? filteredData.reduce((acc, item) => acc + (item.responseTime || 0), 0) / filteredData.length
            : 0,
        successRate: filteredData.length > 0
            ? (filteredData.filter(item => item.success).length / filteredData.length) * 100
            : 0,
        modelUsage: filteredData.reduce((acc, item) => {
            if (item.model) {
                acc[item.model] = (acc[item.model] || 0) + 1;
            }
            return acc;
        }, {}),
        topPromptTypes: filteredData.reduce((acc, item) => {
            if (item.type) {
                acc[item.type] = (acc[item.type] || 0) + 1;
            }
            return acc;
        }, {}),
        dailyUsage: filteredData.reduce((acc, item) => {
            const date = format(new Date(item.timestamp), 'yyyy-MM-dd');
            acc[date] = (acc[date] || 0) + 1;
            return acc;
        }, {}),
        averageRating: filteredData.length > 0
            ? filteredData.reduce((acc, item) => acc + (item.rating || 0), 0) / filteredData.length
            : 0,
        costBreakdown: filteredData.reduce((acc, item) => {
            acc.total += (item.cost || 0);
            if (item.model) {
                acc[item.model] = (acc[item.model] || 0) + (item.cost || 0);
            }
            return acc;
        }, { total: 0 }),
        peakHours: filteredData.reduce((acc, item) => {
            const hour = new Date(item.timestamp).getHours();
            acc[hour] = (acc[hour] || 0) + 1;
            return acc;
        }, {})
    };
};

/**
 * Transform metrics into chart-ready data
 * @param {Object} metrics - Calculated metrics
 * @returns {Object} Transformed chart data
 */
export const transformChartData = (metrics) => {
    if (!metrics) return {};

    return {
        dailyUsage: Object.entries(metrics.dailyUsage).map(([date, count]) => ({
            date,
            requests: count
        })),
        modelUsage: Object.entries(metrics.modelUsage).map(([model, count]) => ({
            name: model,
            value: count
        })),
        hourlyUsage: Object.entries(metrics.peakHours).map(([hour, count]) => ({
            hour: `${hour}:00`,
            requests: count
        }))
    };
};

/**
 * Generate insights from metrics
 * @param {Object} metrics - Calculated metrics
 * @returns {Object} Generated insights
 */
export const getInsights = (metrics) => {
    if (!metrics) return {};

    const peakHour = Object.entries(metrics.peakHours)
        .reduce((a, b) => (b[1] > a[1] ? b : a), [0, 0])[0];

    const topModel = Object.entries(metrics.modelUsage)
        .reduce((a, b) => (b[1] > a[1] ? b : a), ['none', 0])[0];

    const modelUsagePercentage = (metrics.modelUsage[topModel] / metrics.totalRequests) * 100;

    return {
        peakUsageTime: `${peakHour}:00 - ${(parseInt(peakHour) + 1) % 24}:00`,
        popularModel: {
            name: topModel,
            percentage: modelUsagePercentage.toFixed(1)
        },
        averageResponseTime: metrics.averageResponseTime.toFixed(2)
    };
};

/**
 * Track an analytics event
 */
export const trackEvent = async (eventName, properties = {}) => {
    try {
        if (process.env.NODE_ENV === 'development') {
            mockAnalytics.track(eventName, properties);
            return;
        }

        const payload = {
            event: eventName,
            properties,
            timestamp: new Date().toISOString(),
            sessionId: localStorage.getItem('sessionId'),
            userId: localStorage.getItem('userId')
        };

        const response = await analyticsInstance.post('/track', payload);
        return response.data;
    } catch (error) {
        console.warn('Analytics tracking error:', {
            eventName,
            properties,
            error: error.message
        });
        return { success: false, error: error.message };
    }
};

/**
 * Initialize analytics with user data
 */
export const initializeAnalytics = (userData = {}) => {
    const sessionId = Math.random().toString(36).substring(2);
    localStorage.setItem('sessionId', sessionId);

    if (userData.id) {
        localStorage.setItem('userId', userData.id);
    }

    trackEvent('session_start', {
        sessionId,
        ...userData,
        userAgent: navigator.userAgent,
        screenSize: `${window.screen.width}x${window.screen.height}`
    });
};

/**
 * Create a throttled version of trackEvent for high-frequency events
 */
export const throttledTrackEvent = (eventName, properties = {}, delay = 1000) => {
    let lastCall = 0;
    return () => {
        const now = Date.now();
        if (now - lastCall >= delay) {
            lastCall = now;
            trackEvent(eventName, properties);
        }
    };
};

export default {
    trackEvent,
    initializeAnalytics,
    throttledTrackEvent,
    calculateMetrics,
    transformChartData,
    getInsights,
    TIME_RANGES
};