import {
    AnalyticsPanel,
    CharacteristicsPanel,
    EdgesPanel,
    InputPanel,
    NodesPanel,
    ResponsesPanel,
    WritingPanel
} from '../components/Navigator/Panels/index.js';

export const DEFAULT_STATES = {
    sliders: {
        creativity: 50,
        formality: 50,
        empathy: 50,
        humor: 50,
    },
    diagram: {
        name: 'New Diagram',
        description: '',
        nodes: [],
        edges: [],
    },
};

export const EDGE_TYPES = {
    DEFAULT: 'default',
    STEP: 'step',
    SMOOTH: 'smooth',
    STRAIGHT: 'straight',
    NEON: 'neon',
    PULSE: 'pulse',
    QUANTUM: 'quantum',
    CIRCUIT: 'circuit',
};

export const NODE_TYPES = {
    RECTANGLE: 'rectangle',
    CIRCLE: 'circle',
    DIAMOND: 'diamond',
    PARALLELOGRAM: 'parallelogram',
    HEXAGON: 'hexagon',
    STAR: 'star',
    TRAPEZOID: 'trapezoid',
    OVAL: 'oval',
    CLOUD: 'cloud',
    CYLINDER: 'cylinder',
    INPUT: 'input',
    OUTPUT: 'output',
    DEFAULT: 'default',
    PROCESS: 'process',
    DECISION: 'decision',
    CUSTOM: 'custom',
};

export const PANEL_TYPES = {
    ANALYTICS: 'analytics',  // Added Analytics panel type
    WRITING: 'writing',
    NODES: 'nodes',
    EDGES: 'edges',
    INPUT: 'input',
    RESPONSES: 'responses',
    CHARACTERISTICS: 'characteristics',
};

export const PANEL_COMPONENTS = {
    [PANEL_TYPES.ANALYTICS]: AnalyticsPanel,
    [PANEL_TYPES.CHARACTERISTICS]: CharacteristicsPanel,
    [PANEL_TYPES.EDGES]: EdgesPanel,
    [PANEL_TYPES.INPUT]: InputPanel,
    [PANEL_TYPES.NODES]: NodesPanel,
    [PANEL_TYPES.RESPONSES]: ResponsesPanel,
    [PANEL_TYPES.WRITING]: WritingPanel
};

export const VIEW_TYPES = {
    DOCUMENT: 'document',
    DIAGRAM: 'diagram',
};

export const TAB_TYPES = {
    AI: 'ai',
    REGULAR: 'regular',
};

export const AI_TABS = [
    PANEL_TYPES.ANALYTICS,  // Added Analytics to AI tabs
    PANEL_TYPES.INPUT,
    PANEL_TYPES.RESPONSES,
    PANEL_TYPES.CHARACTERISTICS,
];

export const REGULAR_TABS = [
    PANEL_TYPES.WRITING,
    PANEL_TYPES.NODES,
    PANEL_TYPES.EDGES,
];

export const STORAGE_KEYS = {
    PANEL_STATE: 'panel_state',
    DIAGRAM_DATA: 'diagram_data',
    USER_SETTINGS: 'user_settings',
    THEME: 'theme_preference',
};

export const MESSAGE_TYPES = {
    USER: 'user',
    ASSISTANT: 'assistant',
    SYSTEM: 'system',
};

export const RESPONSE_PROVIDERS = {
    CLAUDE: 'Claude',
    OPENAI: 'OpenAI',
};

export const PANEL_DIMENSIONS = {
    FLOATING: {
        width: '400px',
        height: 'calc(100vh - 2rem)',
    },
    DOCKED: {
        width: '33.333%',
        height: '100%',
    },
    EXPANDED: {
        width: '100%',
        height: '100%',
    },
};

export const ERROR_MESSAGES = {
    PANEL_ACCESS: 'You do not have permission to access this panel',
    SAVE_FAILED: 'Failed to save diagram',
    EXPORT_FAILED: 'Failed to export diagram',
    IMPORT_FAILED: 'Failed to import diagram',
    INVALID_FILE: 'Invalid file format',
    API_ERROR: 'Failed to communicate with the server',
    AUTH_REQUIRED: 'Authentication required for this feature',
    VALIDATION_ERROR: 'Invalid data format',
    SHARE_FAILED: 'Failed to share diagram',
    COPY_FAILED: 'Failed to copy to clipboard',
};

export const SUCCESS_MESSAGES = {
    SAVE_SUCCESS: 'Diagram saved successfully',
    EXPORT_SUCCESS: 'Diagram exported successfully',
    IMPORT_SUCCESS: 'Diagram imported successfully',
    SHARE_SUCCESS: 'Diagram shared successfully',
    COPY_SUCCESS: 'Content copied to clipboard',
    RESET_SUCCESS: 'Panel reset successfully',
};



export const NAVIGATION_EVENTS = {
    TAB_CHANGE: 'tab_change',
    VIEW_CHANGE: 'view_change',
    PANEL_TOGGLE: 'panel_toggle',
    AI_MODE_TOGGLE: 'ai_mode_toggle',
};

export const ANIMATION_DURATION = {
    FAST: 150,
    NORMAL: 300,
    SLOW: 500,
};

export const THEME = {
    LIGHT: 'light',
    DARK: 'dark',
    SYSTEM: 'system',
};

export const UI = {
    MIN_PANEL_WIDTH: 320,
    MAX_PANEL_WIDTH: 800,
    PANEL_RESIZE_STEP: 10,
    TOOLBAR_HEIGHT: 48,
    FOOTER_HEIGHT: 40,
};

export const FILE_TYPES = {
    JSON: 'application/json',
    SVG: 'image/svg+xml',
    PNG: 'image/png',
};