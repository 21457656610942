import { useMemo } from 'react';
import { useRightPanelContext } from '../context/RightPanelContext.js';

export function usePanelState() {
    const context = useRightPanelContext();

    const state = useMemo(() => ({
        // Core Panel State
        activeTab: context.activeTab,
        isFloating: context.isFloating,
        isExpanded: context.isExpanded,
        activeView: context.activeView,
        unsavedChanges: context.unsavedChanges,

        // Response Management
        messageGroups: context.messageGroups,
        activeResponseGroup: context.activeResponseGroup,
        responseComparisons: context.responseComparisons,
        aiResponses: context.aiResponses,

        // Diagram State
        selectedDiagram: context.selectedDiagram,
        selectedEdge: context.selectedEdge,
        nodes: context.nodes,
        edges: context.edges,

        // UI State
        isLoading: context.isLoading,
        error: context.error,
        successMessage: context.successMessage,
        isAIMode: context.isAIMode,
    }), [
        context.activeTab,
        context.isFloating,
        context.isExpanded,
        context.activeView,
        context.unsavedChanges,
        context.messageGroups,
        context.activeResponseGroup,
        context.responseComparisons,
        context.aiResponses,
        context.selectedDiagram,
        context.selectedEdge,
        context.nodes,
        context.edges,
        context.isLoading,
        context.error,
        context.successMessage,
        context.isAIMode,
    ]);

    return state;
}

export default usePanelState;