import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import WritingPlatform from './WritingPlatform/WritingPlatform.js';
import DiagramCanvas from './Diagram/Diagram.js';
import './LeftPanel.css';

function LeftPanel({
                       className = '',
                       isRightPanelFloating = false,
                       activeView,
                       documentContent = '',
                       onDocumentChange,
                       onDiagramInit
                   }) {
    const diagramRef = useRef(null);

    useEffect(() => {
        if (diagramRef.current) {
            onDiagramInit(diagramRef.current);
        }
    }, [onDiagramInit]);

    return (
        <div className={`left-panel ${isRightPanelFloating ? 'full-width' : ''} ${className}`}>
            <div className="panel-content">
                {activeView === 'document' ? (
                    <WritingPlatform
                        onChange={onDocumentChange}
                        content={documentContent}
                    />
                ) : (
                    <DiagramCanvas
                        ref={diagramRef}
                        documentId={null}
                    />
                )}
            </div>
        </div>
    );
}

LeftPanel.propTypes = {
    className: PropTypes.string,
    isRightPanelFloating: PropTypes.bool,
    activeView: PropTypes.oneOf(['document', 'diagram']).isRequired,
    documentContent: PropTypes.string,
    onDocumentChange: PropTypes.func.isRequired,
    onDiagramInit: PropTypes.func.isRequired
};

export default LeftPanel;