// src/App.js
import React, { useState } from 'react';
import 'reactflow/dist/style.css';
import LeftPanel from '../LeftPanel/LeftPanel.js';
import RightPanel from '../RightPanel/RightPanel.js';
import { RightPanelProvider } from '../RightPanel/context/RightPanelContext.js';
import Auth from '../Users/Auth.js';
import { AppProvider, useAppContext } from './context/AppContext.js';
import { ApiProvider} from "../Services/api/ApiContext.js";
import './components/styles/App.css';
import './components/styles/main.css';

// AppContent Component
function AppContent() {
    const { token } = useAppContext();
    const [isRightPanelFloating, setIsRightPanelFloating] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeView, setActiveView] = useState('diagram');
    const [documentContent, setDocumentContent] = useState('');
    const [diagramInstance, setDiagramInstance] = useState(null);
    const [selectedEdge, setSelectedEdge] = useState(null);
    const [diagramEdges, setDiagramEdges] = useState([]);
    const [activeTab, setActiveTab] = useState(token ? 'input' : 'nodes');

    const handleDiagramInit = (instance) => {
        setDiagramInstance(instance);
        instance.onEdgesChange = (newEdges) => {
            setDiagramEdges(newEdges);
        };
        instance.onEdgeClick = (edge) => {
            setSelectedEdge(edge);
            setActiveTab('edges');
        };
    };

    return (
        <div className="h-screen flex app">
            {!token ? (
                <Auth />
            ) : (
                <div className="flex-1 relative overflow-hidden main-content">
                    <div className="absolute inset-0 flex">
                        <LeftPanel
                            className={`left-panel transition-all duration-300 ${
                                isRightPanelFloating || isExpanded ? 'w-full' : 'w-2/3'
                            }`}
                            isRightPanelFloating={isRightPanelFloating}
                            activeView={activeView}
                            documentContent={documentContent}
                            onDocumentChange={setDocumentContent}
                            onDiagramInit={handleDiagramInit}
                        />
                        <RightPanelProvider>
                            <RightPanel
                                className={`right-panel transition-all duration-300 ${
                                    isRightPanelFloating ? 'absolute floating' : 'w-1/3'
                                }`}
                                onFloatingChange={setIsRightPanelFloating}
                                activeView={activeView}
                                onViewChange={setActiveView}
                                isExpanded={isExpanded}
                                onExpandChange={setIsExpanded}
                                edges={diagramEdges}
                                diagramInstance={diagramInstance}
                                selectedEdge={selectedEdge}
                                onSelectEdge={setSelectedEdge}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        </RightPanelProvider>
                    </div>
                </div>
            )}
        </div>
    );
}

// App Component
function App() {
    return (
        <AppProvider>
            <ApiProvider>
            <div className="h-screen flex app">
                <AppContent />
            </div>
                </ApiProvider>
        </AppProvider>
    );
}

export default App;
