import React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { cn } from "../utils/utils.js";
import { CheckCircle, Star, Info } from 'lucide-react'; // Add missing icons here

const RadioGroup = React.forwardRef(({ className, ...props }, ref) => {
    return (
        <RadioGroupPrimitive.Root
            className={cn("flex gap-4", className)}
            {...props}
            ref={ref}
        />
    );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef(({ className, children, icon: Icon, ...props }, ref) => {
    return (
        <RadioGroupPrimitive.Item
            ref={ref}
            className={cn(
                "peer flex items-center space-x-2 rounded-md px-4 py-2 border border-gray-700 bg-gray-800 hover:bg-gray-700 focus:bg-gray-700 transition-colors duration-200",
                className
            )}
            {...props}
        >
            <RadioGroupPrimitive.Indicator
                className="flex items-center justify-center absolute inset-0"
            >
                <CheckCircle className="h-5 w-5 text-primary animate-scale-in" />
            </RadioGroupPrimitive.Indicator>
            {Icon && <Icon className="h-5 w-5 text-primary" />}
            <span>{children}</span>
        </RadioGroupPrimitive.Item>
    );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;


const RadioGroupCards = React.forwardRef(({
                                              options,
                                              value,
                                              onValueChange,
                                              orientation = "horizontal",
                                              size = "default",
                                              variant = "default",
                                              className,
                                              ...props
                                          }, ref) => {
    const sizeClasses = {
        small: "gap-2",
        default: "gap-4",
        large: "gap-6"
    };

    const orientationClasses = {
        horizontal: "flex flex-row",
        vertical: "flex flex-col"
    };

    return (
        <RadioGroup
            ref={ref}
            value={value}
            onValueChange={onValueChange}
            className={cn(
                orientationClasses[orientation],
                sizeClasses[size],
                className
            )}
            {...props}
        >
            {options.map((option) => (
                <RadioGroupItem
                    key={option.value}
                    value={option.value}
                    variant={variant}
                    size={size}
                    className="group relative"
                    icon={option.icon}
                >
                    <div className="flex items-center space-x-2">
                        {option.icon && (
                            <option.icon className="h-5 w-5 text-current" />
                        )}
                        <span>{option.label}</span>
                    </div>

                    <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-primary/20 via-primary/10 to-primary/20 opacity-0 transition-opacity duration-300 group-hover:opacity-100 group-data-[state=checked]:opacity-100 animate-glow" />
                </RadioGroupItem>
            ))}
        </RadioGroup>
    );
});
RadioGroupCards.displayName = "RadioGroupCards";

// Inject custom styles if necessary
const styles = `
  @keyframes shimmer {
    from { background-position: 200% 0; }
    to { background-position: -200% 0; }
  }
  @keyframes scale-in {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }
  @keyframes glow {
    0%, 100% { box-shadow: 0 0 10px rgba(32, 178, 170, 0.5); }
    50% { box-shadow: 0 0 20px rgba(32, 178, 170, 1); }
  }
  .animate-shimmer { animation: shimmer 2s linear infinite; }
  .animate-scale-in { animation: scale-in 0.3s ease-out forwards; }
  .animate-glow { animation: glow 2s infinite; }
`;

const StyleInjector = () => (
    <style>
        {styles}
    </style>
);

const Example = () => {
    const [value, setValue] = React.useState("");

    return (
        <>
            <StyleInjector />
            <RadioGroupCards
                options={[
                    { label: "Option 1", value: "option1", icon: Star },
                    { label: "Option 2", value: "option2", icon: Info },
                    { label: "Option 3", value: "option3", icon: CheckCircle },
                ]}
                value={value}
                onValueChange={setValue}
                orientation="horizontal"
                size="default"
                variant="default"
            />
        </>
    );
};

export { RadioGroup, RadioGroupItem, RadioGroupCards, Example };
