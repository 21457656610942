import React, { useState, useContext, useCallback, useEffect } from 'react';
import { AppContext } from '../App/context/AppContext.js';
import { useApi } from '../Services/api/ApiContext.js';
import { apiService } from '../Services/api/api.config.js';
import Logo from '../App/components/assets/Logo.js';
import './Auth.css';

const Auth = () => {
    const { setToken, setTokenBalance, setUsername } = useContext(AppContext);
    const { setError: setApiError } = useApi();
    const [formData, setFormData] = useState({ username: '', password: '', email: '' });
    const [isRegistering, setIsRegistering] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [logoLoaded, setLogoLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLogoLoaded(true), 100);
        return () => clearTimeout(timer);
    }, []);

    const handleChange = useCallback(({ target: { name, value } }) => {
        setFormData(prev => ({ ...prev, [name]: value }));
        setErrorMessage('');
        setApiError(null);
    }, [setApiError]);

    const validateForm = useCallback(() => {
        if (!formData.username || !formData.password) {
            setErrorMessage('Please fill in all required fields.');
            return false;
        }
        if (formData.username.length < 3) {
            setErrorMessage('Username must be at least 3 characters long.');
            return false;
        }
        if (formData.password.length < 8) {
            setErrorMessage('Password must be at least 8 characters long.');
            return false;
        }
        if (isRegistering && formData.email && !formData.email.match(/^\S+@\S+\.\S+$/)) {
            setErrorMessage('Please enter a valid email address.');
            return false;
        }
        return true;
    }, [formData, isRegistering]);

    const handleAuthSuccess = useCallback(({ token, user }) => {
        setToken(token);
        setUsername(user.username);
        setTokenBalance(user.tokenBalance || 0);
        localStorage.setItem('token', token);
        localStorage.setItem('username', user.username);
        setFormData({ username: '', password: '', email: '' });
        setErrorMessage('');
        setApiError(null);
    }, [setToken, setUsername, setTokenBalance, setApiError]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsLoading(true);
        setErrorMessage('');

        try {
            const response = await apiService.auth.login({
                username: formData.username,
                password: formData.password
            });

            if (response?.data) {
                handleAuthSuccess(response.data);
            }
        } catch (error) {
            console.error('Login error:', error);
            setErrorMessage(
                error.response?.data?.error ||
                (error.message === 'Network Error' ? 'Unable to connect to server' : error.message) ||
                'Authentication failed'
            );
        } finally {
            setIsLoading(false);
        }
    };

    const toggleMode = useCallback(() => {
        setIsRegistering(prev => !prev);
        setErrorMessage('');
        setApiError(null);
        setFormData({ username: '', password: '', email: '' });
    }, [setApiError]);

    return (
        <div className="auth-page-container">
            <div className={`auth-container ${logoLoaded ? 'content-loaded' : ''}`}>
                <div className={`logo-container ${logoLoaded ? 'logo-loaded' : ''}`}>
                    <Logo className="auth-logo" />
                    <div className="logo-underline" />
                </div>

                <h2 className="auth-title">
                    {isRegistering ? 'Create Account' : 'Welcome Back'}
                </h2>

                {errorMessage && (
                    <div className="error-message" role="alert">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon-error"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M12 9v2m0 4v.01" />
                            <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
                        </svg>
                        {errorMessage}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="auth-form">
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            disabled={isLoading}
                            required
                            minLength="3"
                            maxLength="30"
                            placeholder="Enter username"
                            autoComplete="username"
                            className={errorMessage ? 'error' : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <div className="password-input-container">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                disabled={isLoading}
                                required
                                minLength="8"
                                placeholder="Enter password"
                                autoComplete={isRegistering ? 'new-password' : 'current-password'}
                                className={errorMessage ? 'error' : ''}
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(prev => !prev)}
                                className="toggle-password"
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                            >
                                {showPassword ? '👁️' : '👁️‍🗨️'}
                            </button>
                        </div>
                    </div>

                    {isRegistering && (
                        <div className="form-group">
                            <label htmlFor="email">Email (optional)</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                disabled={isLoading}
                                placeholder="Enter email"
                                className={errorMessage ? 'error' : ''}
                            />
                        </div>
                    )}

                    <button
                        type="submit"
                        className={`auth-button ${isLoading ? 'loading' : ''}`}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span className="loading-spinner"></span>
                        ) : isRegistering ? (
                            'Create Account'
                        ) : (
                            'Login'
                        )}
                    </button>
                </form>

                <p className="auth-switch">
                    {isRegistering ? 'Already have an account?' : "Don't have an account?"}{' '}
                    <button
                        onClick={toggleMode}
                        className="switch-button"
                        type="button"
                        disabled={isLoading}
                    >
                        {isRegistering ? 'Login' : 'Register'}
                    </button>
                </p>
            </div>
        </div>
    );
};

export default Auth;