// alert.jsx
import * as React from 'react';
import { cva } from 'class-variance-authority';
import { AlertCircle, CheckCircle2, Info, XCircle } from 'lucide-react';

// Alert variants styling configuration
const alertVariants = cva(
    "relative overflow-hidden rounded-lg border p-4 shadow-lg transition-all duration-300 animate-in fade-in-0 slide-in-from-top-1 group cursor-pointer",
    {
        variants: {
            variant: {
                default: [
                    "border-primary-dark bg-gray-900/90",
                    "backdrop-blur-md",
                    "text-white",
                    "shadow-glow",
                    "hover:shadow-neon",
                    "before:absolute before:inset-0 before:bg-gradient-to-r before:from-primary/10 before:to-transparent before:opacity-50",
                ],
                success: [
                    "border-green-500 bg-gray-900/90",
                    "backdrop-blur-md",
                    "text-white",
                    "shadow-glow",
                    "hover:shadow-neon",
                    "before:absolute before:inset-0 before:bg-gradient-to-r before:from-green-500/10 before:to-transparent before:opacity-50",
                ],
                warning: [
                    "border-yellow-500 bg-gray-900/90",
                    "backdrop-blur-md",
                    "text-white",
                    "shadow-glow",
                    "hover:shadow-neon",
                    "before:absolute before:inset-0 before:bg-gradient-to-r before:from-yellow-500/10 before:to-transparent before:opacity-50",
                ],
                destructive: [
                    "border-red-500 bg-gray-900/90",
                    "backdrop-blur-md",
                    "text-white",
                    "shadow-glow",
                    "hover:shadow-neon",
                    "before:absolute before:inset-0 before:bg-gradient-to-r before:from-red-500/10 before:to-transparent before:opacity-50",
                ],
                info: [
                    "border-primary-medium bg-gray-900/90",
                    "backdrop-blur-md",
                    "text-white",
                    "shadow-glow",
                    "hover:shadow-neon",
                    "before:absolute before:inset-0 before:bg-gradient-to-r before:from-primary-medium/10 before:to-transparent before:opacity-50",
                ],
            },
            hasIcon: {
                true: "pl-11",
            },
        },
        defaultVariants: {
            variant: "default",
            hasIcon: false,
        },
    }
);

const iconVariants = {
    default: { icon: AlertCircle, className: "text-primary" },
    info: { icon: Info, className: "text-primary" },
    success: { icon: CheckCircle2, className: "text-green-400" },
    warning: { icon: AlertCircle, className: "text-yellow-400" },
    destructive: { icon: XCircle, className: "text-red-400" },
};

const Alert = React.forwardRef(({
                                    className,
                                    variant = "default",
                                    children,
                                    showIcon = true,
                                    ...props
                                }, ref) => {
    const IconComponent = iconVariants[variant]?.icon;

    return (
        <div
            ref={ref}
            role="alert"
            className={alertVariants({ variant, hasIcon: showIcon && IconComponent, className })}
            {...props}
        >
            {showIcon && IconComponent && (
                <div className="absolute left-4 top-4 flex h-5 w-5 items-center justify-center animate-glow">
                    <IconComponent className={`h-5 w-5 ${iconVariants[variant].className}`} />
                </div>
            )}
            <div className="relative z-10">{children}</div>

            {/* Animated border glow effect */}
            <div className="absolute inset-px rounded-lg bg-gradient-to-r from-transparent via-primary/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
        </div>
    );
});
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef(({ className, ...props }, ref) => (
    <h5
        ref={ref}
        className={`mb-1 font-semibold leading-none tracking-tight text-white ${className || ''}`}
        {...props}
    />
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={`text-sm text-gray-300 ${className || ''}`}
        {...props}
    />
));
AlertDescription.displayName = "AlertDescription";

// Dismiss button component
const AlertDismiss = React.forwardRef(({ className, ...props }, ref) => (
    <button
        ref={ref}
        className={`
          absolute right-4 top-4 rounded-full p-1
          text-gray-400 hover:text-white transition-colors
          focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2
          ${className || ''}
        `}
        {...props}
    >
        <XCircle className="h-4 w-4 animate-spin-slow" />
    </button>
));
AlertDismiss.displayName = "AlertDismiss";

export { Alert, AlertTitle, AlertDescription, AlertDismiss };
