import axios from 'axios';

export const API_CONFIG = {
    BASE_URL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
    TIMEOUT: 30000,
    ENDPOINTS: {
        // Auth endpoints
        LOGIN: '/api/users/login',
        REGISTER: '/api/users/register',
        PROFILE: '/api/users/profile',

        // AI endpoints
        AI_RESPONSES: '/api/ai/respond',
        AI_CHARACTERISTICS: '/api/settings/ai-characteristics',

        // Diagram endpoints
        DIAGRAMS: '/api/diagrams',
        NODES: '/api/diagrams/nodes',
        EDGES: '/api/diagrams/edges'
    }
};

const createAxiosInstance = () => {
    const instance = axios.create({
        baseURL: API_CONFIG.BASE_URL,
        timeout: API_CONFIG.TIMEOUT,
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true  // Keep this
    });

    instance.interceptors.request.use(
        config => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );

    instance.interceptors.response.use(
        response => response,
        error => {
            if (!error.response) {
                return Promise.reject(new Error('Network error: Unable to connect to server'));
            }

            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
                return Promise.reject(new Error('Session expired'));
            }

            if (error.response.status === 403) {
                console.error('CORS Error:', error.response.data);
                return Promise.reject(new Error('Access denied'));
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

const api = createAxiosInstance();

export const apiService = {
    auth: {
        login: (credentials) => api.post(API_CONFIG.ENDPOINTS.LOGIN, credentials),
        register: (credentials) => api.post(API_CONFIG.ENDPOINTS.REGISTER, credentials),
        getProfile: () => api.get(API_CONFIG.ENDPOINTS.PROFILE)
    },
    ai: {
        createResponse: (messages) => {
            if (!messages?.[0]?.content) {
                return Promise.reject(new Error('Message content is required'));
            }
            return api.post(API_CONFIG.ENDPOINTS.AI_RESPONSES, { messages });
        },
        getCharacteristics: () => api.get(API_CONFIG.ENDPOINTS.AI_CHARACTERISTICS)
    },
    diagrams: {
        getAll: () => api.get(API_CONFIG.ENDPOINTS.DIAGRAMS),
        getById: (id) => api.get(`${API_CONFIG.ENDPOINTS.DIAGRAMS}/${id}`),
        create: (data) => api.post(API_CONFIG.ENDPOINTS.DIAGRAMS, data),
        update: (id, data) => api.put(`${API_CONFIG.ENDPOINTS.DIAGRAMS}/${id}`, data),
        delete: (id) => api.delete(`${API_CONFIG.ENDPOINTS.DIAGRAMS}/${id}`),
        share: (id, options) => api.post(`${API_CONFIG.ENDPOINTS.DIAGRAMS}/${id}/share`, options)
    }
};

export default createAxiosInstance;
