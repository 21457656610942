import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Link,
    Unlink,
    AlertTriangle,
    Save,
    X,
    ArrowRight,
    Edit2,
} from 'lucide-react';
import { Button } from '../../../../../App/components/ui/button.jsx';
import {
    Alert,
    AlertDescription,
} from '../../../../../App/components/ui/alert.jsx';
import EDGE_TYPES, {
    getEffectiveEdgeType,
} from '../../../../../LeftPanel/Diagram/components/edges/edgeTypes.js';
import './EdgesPanel.css';

// Edge Type Button Component
const EdgeTypeButton = ({ config, isSelected, onClick }) => {
    const Icon = config.icon || Link;

    return (
        <button
            type="button"
            onClick={onClick}
            className={`connection-type-btn ${isSelected ? 'active' : ''}`}
        >
            <div className="flex items-center gap-4">
                <div
                    className="connection-icon"
                    style={{ backgroundColor: config.color }}
                >
                    <Icon />
                </div>
                <div className="flex-1">
                    <div className="font-semibold text-lg">{config.label}</div>
                    <div className="text-sm text-gray-400 mt-1">
                        {config.description}
                    </div>
                </div>
            </div>
            <div className="edge-preview">
                <svg className="w-full h-full">
                    <path
                        d="M0,2 C50,0 70,4 100,2"
                        fill="none"
                        stroke={config.color}
                        strokeWidth="2"
                        className={`${config.styleConfig.className || ''} ${
                            config.animated ? 'animate-flow' : ''
                        }`}
                        style={{
                            ...config.styleConfig,
                            strokeDasharray: config.styleConfig.strokeDasharray,
                        }}
                    />
                </svg>
            </div>
        </button>
    );
};

EdgeTypeButton.propTypes = {
    config: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

const EdgesPanel = ({
                        edges = [],
                        nodes = [],
                        onUpdateEdge,
                        onDeleteEdge,
                        selectedEdge,
                        onSelectEdge,
                        onEdgeTypeSelect
                    }) => {
    const [editingEdge, setEditingEdge] = useState(null);
    const [error, setError] = useState('');
    const [edgeLabel, setEdgeLabel] = useState('');

    const getNodeLabel = useCallback(
        (nodeId) => {
            const node = nodes.find((n) => n.id === nodeId);
            return node?.data?.label || nodeId;
        },
        [nodes]
    );

    const handleStartEdit = useCallback((edge) => {
        setEditingEdge(edge);
        setEdgeLabel(edge.data?.label || edge.label || '');
    }, []);

    const handleSaveEdit = useCallback(() => {
        try {
            if (!editingEdge) return;

            onUpdateEdge(editingEdge.id, {
                ...editingEdge,
                data: {
                    ...(editingEdge.data || {}),
                    label: edgeLabel.trim(),
                },
                label: edgeLabel.trim(),
            });
            setEditingEdge(null);
            setError('');
        } catch (err) {
            setError('Failed to update connection');
        }
    }, [editingEdge, edgeLabel, onUpdateEdge]);

    const handleTypeSelect = useCallback(
        (type) => {
            if (!selectedEdge) return;
            onEdgeTypeSelect(type);
        },
        [selectedEdge, onEdgeTypeSelect]
    );

    const handleEdgeClick = useCallback(
        (edge) => {
            onSelectEdge(edge);
        },
        [onSelectEdge]
    );

    return (
        <div className="edges-panel">
            {error && (
                <Alert variant="destructive" className="mb-4">
                    <AlertTriangle className="w-4 h-4 mr-2" />
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            <section className="mb-8">
                <h3 className="text-2xl font-bold mb-6">Connection Types</h3>
                <div className="connection-types-grid">
                    {Object.entries(EDGE_TYPES).map(([type, config]) => {
                        const currentEdgeType = selectedEdge
                            ? getEffectiveEdgeType(selectedEdge)
                            : null;
                        const isSelected = currentEdgeType === type;

                        return (
                            <EdgeTypeButton
                                key={type}
                                config={config}
                                isSelected={isSelected}
                                onClick={() => handleTypeSelect(type)}
                            />
                        );
                    })}
                </div>
            </section>

            <section>
                <h3 className="text-2xl font-bold mb-6">Active Connections</h3>
                <div className="space-y-4">
                    {edges?.length === 0 ? (
                        <div className="text-center py-8 text-gray-500 bg-[#333] rounded-lg border border-dashed border-gray-500">
                            No connections yet. Connect nodes by dragging between them.
                        </div>
                    ) : (
                        edges.map((edge) => (
                            <div
                                key={edge.id}
                                className={`connection-list-item ${
                                    selectedEdge?.id === edge.id ? 'selected' : ''
                                }`}
                                onClick={() => handleEdgeClick(edge)}
                            >
                                {editingEdge?.id === edge.id ? (
                                    <div className="flex items-center gap-4">
                                        <input
                                            type="text"
                                            value={edgeLabel}
                                            onChange={(e) => setEdgeLabel(e.target.value)}
                                            className="flex-1 px-3 py-2 border rounded bg-[#333] text-white focus:ring-2 focus:ring-[#20b2aa] focus:border-[#20b2aa] transition-all duration-200"
                                            placeholder="Connection label"
                                            autoFocus
                                        />
                                        <div className="flex gap-2">
                                            <Button
                                                size="sm"
                                                variant="ghost"
                                                onClick={handleSaveEdit}
                                                className="text-[#20b2aa] hover:bg-[#333]"
                                            >
                                                <Save className="w-5 h-5" />
                                            </Button>
                                            <Button
                                                size="sm"
                                                variant="ghost"
                                                onClick={() => setEditingEdge(null)}
                                                className="text-gray-500 hover:bg-[#333]"
                                            >
                                                <X className="w-5 h-5" />
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-between">
                                        <div className="connection-info">
                                            <div
                                                className={`status-indicator ${
                                                    EDGE_TYPES[getEffectiveEdgeType(edge)]?.animated
                                                        ? 'animate-pulse'
                                                        : ''
                                                }`}
                                                style={{
                                                    backgroundColor:
                                                        EDGE_TYPES[getEffectiveEdgeType(edge)]?.color ||
                                                        '#20b2aa',
                                                }}
                                            />
                                            <div className="node-labels">
                                                <span className="node-label">
                                                    {getNodeLabel(edge.source)}
                                                </span>
                                                <ArrowRight className="w-5 h-5 arrow-icon" />
                                                <span className="node-label">
                                                    {getNodeLabel(edge.target)}
                                                </span>
                                            </div>
                                            {edge.label && (
                                                <span className="edge-label">({edge.label})</span>
                                            )}
                                        </div>
                                        <div className="action-buttons">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleStartEdit(edge);
                                                }}
                                            >
                                                <Edit2 />
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (window.confirm('Delete this connection?')) {
                                                        onDeleteEdge(edge.id);
                                                    }
                                                }}
                                            >
                                                <Unlink />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
            </section>
        </div>
    );
};

EdgesPanel.propTypes = {
    edges: PropTypes.array,
    nodes: PropTypes.array,
    onUpdateEdge: PropTypes.func.isRequired,
    onDeleteEdge: PropTypes.func.isRequired,
    selectedEdge: PropTypes.object,
    onSelectEdge: PropTypes.func,
    onEdgeTypeSelect: PropTypes.func,
};

EdgesPanel.defaultProps = {
    edges: [],
    nodes: [],
    selectedEdge: null,
    onSelectEdge: () => {},
    onEdgeTypeSelect: () => {},
};

export default EdgesPanel;