import React, { createContext, useState, useEffect, useContext } from 'react';
import { apiService, api } from '../../Services/api/api.config.js';

export const AppContext = createContext();

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

export const AppProvider = ({ children }) => {
    const [tokenBalance, setTokenBalance] = useState(0);
    const [token, setToken] = useState(localStorage.getItem('token') || null);
    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [userSettings, setUserSettings] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isAIEnabled, setIsAIEnabled] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!token) {
                setIsLoading(false);
                return;
            }

            try {
                const response = await apiService.auth.getProfile();
                const userData = response.data;

                setTokenBalance(userData.tokenBalance || 0);
                setUsername(userData.username);
                setUserSettings(userData.settings || {});
                setError(null);

                if (process.env.NODE_ENV === 'development') {
                    console.log('User data fetched:', userData);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError(error.message || 'Error fetching user data');

                if (error.response?.status === 401) {
                    handleLogout();
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, [token]);

    const handleAuthentication = async (endpoint, credentials) => {
        try {
            setIsLoading(true);
            setError(null);

            const response = await apiService.auth[endpoint](credentials);
            const { token: newToken, user } = response.data;

            localStorage.setItem('token', newToken);
            localStorage.setItem('username', user.username);

            setToken(newToken);
            setUsername(user.username);
            setTokenBalance(user.tokenBalance || 0);
            setUserSettings(user.settings || {});

            return { success: true };
        } catch (error) {
            console.error('Authentication error:', error);
            setError(error.response?.data?.error || 'Authentication failed');
            return {
                success: false,
                error: error.response?.data?.error || 'Authentication failed'
            };
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = async () => {
        setIsLoading(true);
        try {
            if (token) {
                await api.post('/api/users/logout').catch(console.error);
            }
        } finally {
            setToken(null);
            setUsername('');
            setTokenBalance(0);
            setUserSettings({});
            setError(null);
            localStorage.clear();
            setIsLoading(false);
        }
    };

    const contextValue = {
        token,
        setToken,
        username,
        setUsername,
        tokenBalance,
        setTokenBalance,
        userSettings,
        setUserSettings,
        isLoading,
        error,
        setError,
        isAIEnabled,
        setIsAIEnabled,
        handleAuthentication,
        handleLogout,
        api,
        apiService
    };

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};

export { api };