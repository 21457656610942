import React, { useCallback, useRef, useState, useEffect } from 'react';
import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    useNodesState,
    useEdgesState,
    addEdge,
} from 'reactflow';
import CustomNode from './components/nodes/CustomNode.js';
import EDGE_TYPES, {
    CustomEdge,
    getEdgeStyle as getBaseEdgeStyle,
    getEffectiveEdgeType,
} from './components/edges/edgeTypes.js';
import 'reactflow/dist/style.css';
import './Diagram.css';
import './components/edges/edgeStyles.css';

const proOptions = { hideAttribution: true };

const nodeTypes = {
    rectangle: CustomNode,
    circle: CustomNode,
    diamond: CustomNode,
    parallelogram: CustomNode,
    hexagon: CustomNode,
    star: CustomNode,
    trapezoid: CustomNode,
    oval: CustomNode,
    cloud: CustomNode,
    cylinder: CustomNode,
};

const edgeTypes = Object.fromEntries(
    Object.keys(EDGE_TYPES).map((type) => [type, CustomEdge])
);

const formatEdge = (edge, selectedId = null) => {
    if (!edge) return null;

    const edgeType = getEffectiveEdgeType(edge);
    const config = EDGE_TYPES[edgeType];
    const isSelected = selectedId === edge.id;

    return {
        ...edge,
        type: edgeType,
        style: getBaseEdgeStyle(edgeType, isSelected),
        animated: config?.animated || false,
        selected: isSelected,
        data: {
            ...edge.data,
            type: edgeType,
        },
    };
};

const DiagramCanvas = React.forwardRef((props, ref) => {
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [selectedEdge, setSelectedEdge] = useState(null);

    useEffect(() => {
        setEdges((currentEdges) =>
            currentEdges
                .filter(Boolean)
                .map((edge) => formatEdge(edge, selectedEdge?.id))
                .filter(Boolean)
        );
    }, [selectedEdge]);

    const onEdgeClick = useCallback(
        (event, edge) => {
            event.stopPropagation();
            setSelectedEdge(edge);
            if (ref.current?.onEdgeClick) {
                ref.current.onEdgeClick(edge);
            }
        },
        [ref]
    );

    const onEdgesChangeHandler = useCallback(
        (changes) => {
            onEdgesChange(changes);
            if (ref.current?.onEdgesChange) {
                ref.current.onEdgesChange(edges);
            }
        },
        [onEdgesChange, edges, ref]
    );

    const onConnect = useCallback(
        (params) => {
            const edgeType = params.type || 'componentCommunication';
            const newEdge = formatEdge({
                ...params,
                id: `e${params.source}-${params.target}`,
                type: edgeType,
                data: {
                    type: edgeType,
                    label: '',
                },
            });

            setEdges((eds) => {
                const updatedEdges = addEdge(newEdge, eds);
                if (ref.current?.onEdgesChange) {
                    ref.current.onEdgesChange(updatedEdges);
                }
                return updatedEdges;
            });
        },
        [setEdges, ref]
    );

    React.useImperativeHandle(ref, () => ({
        addNode: (type, position) => {
            const newNode = {
                id: `${type}-${Date.now()}`,
                type,
                position,
                data: {
                    label: `${type.charAt(0).toUpperCase() + type.slice(1)} Node`,
                    shape: type,
                },
            };
            setNodes((nds) => nds.concat(newNode));
        },
        updateNode: (id, data) => {
            setNodes((nds) =>
                nds.map((node) => (node.id === id ? { ...node, ...data } : node))
            );
        },
        deleteNode: (id) => {
            setNodes((nds) => nds.filter((node) => node.id !== id));
            setEdges((eds) =>
                eds.filter((edge) => edge.source !== id && edge.target !== id)
            );
        },
        addEdge: (params) => {
            const edgeType = params.type || 'componentCommunication';
            const newEdge = formatEdge({
                ...params,
                id: `e${params.source}-${params.target}`,
                type: edgeType,
                data: {
                    type: edgeType,
                    label: params.label || '',
                },
            });
            setEdges((eds) => addEdge(newEdge, eds));
        },
        updateEdge: (id, data) => {
            setEdges((eds) =>
                eds.map((edge) => {
                    if (edge.id === id) {
                        const updatedEdge = formatEdge({
                            ...edge,
                            ...data,
                            data: {
                                ...edge.data,
                                ...data.data,
                            },
                        });
                        return updatedEdge;
                    }
                    return edge;
                })
            );
        },
        deleteEdge: (id) => {
            setEdges((eds) => eds.filter((edge) => edge.id !== id));
        },
        getNodes: () => nodes,
        getEdges: () => edges,
        getCurrentEdges: () => edges,
        onEdgesChange: (callback) => {
            ref.current.onEdgesChange = callback;
        },
        onEdgeClick: (callback) => {
            ref.current.onEdgeClick = callback;
        },
        selectEdge: (edge) => setSelectedEdge(edge),
    }));

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');

            if (!type || !reactFlowInstance) return;

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            const newNode = {
                id: `${type}-${Date.now()}`,
                type,
                position,
                data: { label: `${type.charAt(0).toUpperCase() + type.slice(1)} Node`, shape: type },
            };

            setNodes((nds) => nds.concat(newNode));
        },
        [reactFlowInstance, setNodes]
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    return (
        <div className="diagram-view">
            <div ref={reactFlowWrapper} className="diagram-canvas">
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChangeHandler}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    onEdgeClick={onEdgeClick}
                    fitView
                    snapToGrid
                    snapGrid={[15, 15]}
                    proOptions={proOptions}
                    defaultViewport={{ x: 0, y: 0, zoom: 1.2 }}
                    minZoom={0.2}
                    maxZoom={4}
                    deleteKeyCode={['Backspace', 'Delete']}
                >
                    <Background
                        variant="dots"
                        gap={20}
                        size={1}
                        color="rgba(255, 255, 255, 0.1)"
                    />
                    <MiniMap
                        nodeColor={() => '#20b2aa'}
                        maskColor="rgba(32, 178, 170, 0.1)"
                        style={{
                            backgroundColor: 'rgba(26, 26, 26, 0.8)',
                            borderRadius: '8px',
                        }}
                        zoomable
                        pannable
                    />
                    <Controls
                        showInteractive={false}
                        style={{
                            backgroundColor: 'rgba(26, 26, 26, 0.8)',
                            borderRadius: '8px',
                            border: '1px solid rgba(255, 255, 255, 0.1)',
                        }}
                        buttonStyle={{
                            color: '#ffffff',
                        }}
                    />
                </ReactFlow>
            </div>
        </div>
    );
});

DiagramCanvas.displayName = 'DiagramCanvas';

export default DiagramCanvas;