import React from 'react';
import PropTypes from 'prop-types';
import {
    Save,
    Download,
    Upload,
    Share2,
    Copy,
    RefreshCw,
    Settings,
    FileJson,
    FileText,
    LogOut,
    FileEdit
} from 'lucide-react';
import { Button } from '../../../../App/components/ui/button.jsx';
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
} from '../../../../App/components/ui/dropdown-menu.js';
import './ActionButtons.css';

const ActionButtons = ({
                           onSave,
                           onExport,
                           onImport,
                           onShare,
                           onCopy,
                           onReset,
                           onSettings,
                           onLogout,
                           onNewDiagram,
                           isProcessing = false,
                           hasChanges = false
                       }) => {
    const primaryActions = [
        {
            icon: Save,
            label: 'Save',
            onClick: onSave,
            disabled: !hasChanges,
            primary: true
        },
        {
            icon: Download,
            label: 'Export',
            isDropdown: true,
            items: [
                { icon: FileJson, label: 'Export as JSON', onClick: () => onExport('json') },
                { icon: FileText, label: 'Export as Text', onClick: () => onExport('text') }
            ]
        },
        {
            icon: Upload,
            label: 'Import',
            onClick: onImport
        }
    ];

    const secondaryActions = [
        {
            icon: Share2,
            label: 'Share',
            onClick: onShare
        },
        {
            icon: Copy,
            label: 'Copy',
            onClick: onCopy
        },
        {
            icon: RefreshCw,
            label: 'Reset',
            onClick: onReset
        },
        {
            icon: Settings,
            label: 'Settings',
            onClick: onSettings
        }
    ];

    const tertiaryActions = [
        {
            icon: FileEdit,
            label: 'New Diagram',
            onClick: onNewDiagram,
            primary: true
        },
        {
            icon: LogOut,
            label: 'Logout',
            onClick: onLogout,
            variant: 'destructive'
        }
    ];

    const renderAction = (action) => {
        if (action.isDropdown) {
            return (
                <DropdownMenu key={action.label}>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="outline"
                            size="lg"
                            disabled={isProcessing}
                            className="action-button"
                        >
                            <action.icon className="w-5 h-5" />
                            {action.label}
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        {action.items.map(item => (
                            <DropdownMenuItem
                                key={item.label}
                                onClick={item.onClick}
                                className="dropdown-item"
                            >
                                <item.icon className="w-4 h-4 mr-2" />
                                {item.label}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        }

        return (
            <Button
                key={action.label}
                variant={action.variant || (action.primary ? 'default' : 'outline')}
                size="lg"
                onClick={action.onClick}
                disabled={isProcessing || action.disabled}
                className="action-button"
            >
                <action.icon className="w-5 h-5" />
                {action.label}
            </Button>
        );
    };

    return (
        <div className="action-buttons">
            <div className="action-groups">
                <div className="primary-actions">
                    {primaryActions.map(renderAction)}
                </div>
                <div className="secondary-actions">
                    {secondaryActions.map(renderAction)}
                </div>
                <div className="tertiary-actions">
                    {tertiaryActions.map(renderAction)}
                </div>
            </div>
        </div>
    );
};

ActionButtons.propTypes = {
    onSave: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    onImport: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSettings: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onNewDiagram: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool,
    hasChanges: PropTypes.bool
};

export default ActionButtons;