// edgeTypes.js
import React, { memo } from 'react';
import {
    EdgeLabelRenderer,
    getBezierPath,
    getSmoothStepPath,
    getStraightPath,
} from 'reactflow';
import {
    Code,
    Settings,
    Repeat,
    RefreshCw,
    Shuffle,
    Zap,
    Cpu,
    Database,
    Box,
    Layers,
    ArrowRight,
    ArrowLeftRight,
    Sun,
    Moon,
} from 'lucide-react';

// Define base configurations with enhanced visual styles
const BASE_EDGE_TYPES = {
    hookTransfer: {
        label: 'Hook Transfer',
        description: 'Data flow between React context.',
        color: '#ff6b6b',
        style: 'hookTransfer',
        type: 'hookTransfer',
        styleConfig: {
            stroke: '#ff6b6b',
            strokeWidth: 2.5,
            strokeDasharray: '5,5',
            animation: 'hookFlow 2s linear infinite',
            filter: 'drop-shadow(0 0 6px rgba(255, 107, 107, 0.7))',
        },
        icon: RefreshCw,
        animated: true,
    },
    constantFlow: {
        label: 'Constant Flow',
        description: 'Sharing of constants and immutable data.',
        color: '#48dbfb',
        style: 'constantFlow',
        type: 'constantFlow',
        styleConfig: {
            stroke: '#48dbfb',
            strokeWidth: 2,
            strokeDasharray: '5,5',
            animation: 'constantFlow 4s linear infinite',
            filter: 'drop-shadow(0 0 4px rgba(72, 219, 251, 0.5))',
        },
        icon: Box,
        animated: true,
    },
    processPipeline: {
        label: 'Process Pipeline',
        description: 'Sequential process execution flow.',
        color: '#1dd1a1',
        style: 'processPipeline',
        type: 'processPipeline',
        styleConfig: {
            stroke: '#1dd1a1',
            strokeWidth: 3,
            animation: 'processFlow 3s ease-in-out infinite',
            filter: 'drop-shadow(0 0 8px rgba(29, 209, 161, 0.7))',
        },
        icon: Settings,
        animated: true,
    },
    eventHandler: {
        label: 'Event Handler',
        description: 'Connection between events and their handlers.',
        color: '#feca57',
        style: 'eventHandler',
        type: 'eventHandler',
        styleConfig: {
            stroke: '#feca57',
            strokeWidth: 2,
            strokeDasharray: '10,5',
            animation: 'handlerFlow 3s linear infinite',
            filter: 'drop-shadow(0 0 6px rgba(254, 202, 87, 0.7))',
        },
        icon: Zap,
        animated: true,
    },
    componentCommunication: {
        label: 'Component Communication',
        description: 'Data exchange between components.',
        color: '#54a0ff',
        style: 'componentCommunication',
        type: 'componentCommunication',
        styleConfig: {
            stroke: '#54a0ff',
            strokeWidth: 2.5,
            animation: 'componentFlow 2.5s ease-in-out infinite',
            filter: 'drop-shadow(0 0 6px rgba(84, 160, 255, 0.7))',
        },
        icon: Layers,
        animated: true,
    },
    dataTransformation: {
        label: 'Data Transformation',
        description: 'Conversion or manipulation of data.',
        color: '#5f27cd',
        style: 'dataTransformation',
        type: 'dataTransformation',
        styleConfig: {
            stroke: '#5f27cd',
            strokeWidth: 2.5,
            strokeDasharray: '8,4',
            animation: 'transformationFlow 3s linear infinite',
            filter: 'drop-shadow(0 0 6px rgba(95, 39, 205, 0.7))',
        },
        icon: Shuffle,
        animated: true,
    },
    serviceCall: {
        label: 'Service Call',
        description: 'Interactions with external services or APIs.',
        color: '#ee5253',
        style: 'serviceCall',
        type: 'serviceCall',
        styleConfig: {
            stroke: '#ee5253',
            strokeWidth: 2,
            strokeDasharray: '2,6',
            animation: 'serviceFlow 2s ease-in-out infinite',
            filter: 'drop-shadow(0 0 6px rgba(238, 82, 83, 0.7))',
        },
        icon: Cpu,
        animated: true,
    },
    stateManagement: {
        label: 'State Management',
        description: 'Flow of stateful data.',
        color: '#10ac84',
        style: 'stateManagement',
        type: 'stateManagement',
        styleConfig: {
            stroke: '#10ac84',
            strokeWidth: 3,
            animation: 'stateFlow 3s ease-in-out infinite',
            filter: 'drop-shadow(0 0 8px rgba(16, 172, 132, 0.7))',
        },
        icon: Database,
        animated: true,
    },
    logicFlow: {
        label: 'Logic Flow',
        description: 'Conditional or logical operations.',
        color: '#576574',
        style: 'logicFlow',
        type: 'logicFlow',
        styleConfig: {
            stroke: '#576574',
            strokeWidth: 2,
            strokeDasharray: '4,8',
            animation: 'logicFlow 2.5s linear infinite',
            filter: 'drop-shadow(0 0 4px rgba(87, 101, 116, 0.7))',
        },
        icon: Code,
        animated: true,
    },
    dataFetch: {
        label: 'Data Fetch',
        description: 'Retrieval of data from sources.',
        color: '#00d2d3',
        style: 'dataFetch',
        type: 'dataFetch',
        styleConfig: {
            stroke: '#00d2d3',
            strokeWidth: 2.5,
            animation: 'fetchFlow 2s linear infinite',
            filter: 'drop-shadow(0 0 6px rgba(0, 210, 211, 0.7))',
        },
        icon: Repeat,
        animated: true,
    },
    // Unique Edge with Animated Glowing Orb
    glowingOrb: {
        label: 'Glowing Orb',
        description: 'A magical data flow with an animated glowing orb.',
        color: '#ff9f43',
        style: 'glowingOrb',
        type: 'glowingOrb',
        styleConfig: {
            stroke: '#ff9f43',
            strokeWidth: 2.5,
            animation: 'glowingOrbAnimation 4s ease-in-out infinite',
            filter: 'url(#glowFilter)',
        },
        icon: Sun,
        animated: true,
    },
    // Edge with Single Arrowhead
    unidirectional: {
        label: 'Unidirectional Flow',
        description: 'Data flows in a single direction.',
        color: '#0abde3',
        style: 'unidirectional',
        type: 'unidirectional',
        styleConfig: {
            stroke: '#0abde3',
            strokeWidth: 2,
            markerEnd: 'url(#arrowhead)',
            animation: 'unidirectionalFlow 3s linear infinite',
            filter: 'drop-shadow(0 0 6px rgba(10, 189, 227, 0.7))',
        },
        icon: ArrowRight,
        animated: true,
    },
    // Edge with Double Arrowheads
    bidirectional: {
        label: 'Bidirectional Flow',
        description: 'Data flows in both directions.',
        color: '#1dd1a1',
        style: 'bidirectional',
        type: 'bidirectional',
        styleConfig: {
            stroke: '#1dd1a1',
            strokeWidth: 2,
            markerStart: 'url(#arrowhead)',
            markerEnd: 'url(#arrowhead)',
            animation: 'bidirectionalFlow 3s linear infinite',
            filter: 'drop-shadow(0 0 6px rgba(29, 209, 161, 0.7))',
        },
        icon: ArrowLeftRight,
        animated: true,
    },
    // High-Tech Laser Beam Edge
    laserBeam: {
        label: 'Laser Beam',
        description: 'A high-tech edge resembling a laser beam.',
        color: '#ff4757',
        style: 'laserBeam',
        type: 'laserBeam',
        styleConfig: {
            stroke: 'url(#laserGradient)',
            strokeWidth: 3,
            animation: 'laserBeamAnimation 2s linear infinite',
            filter: 'drop-shadow(0 0 12px rgba(255, 71, 87, 0.8))',
        },
        icon: Moon,
        animated: true,
    },
};

// Edge path functions
const getEdgePath = (
    type,
    { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition }
) => {
    switch (type) {
        case 'hookTransfer':
        case 'serviceCall':
            return getSmoothStepPath({
                sourceX,
                sourceY,
                sourcePosition,
                targetX,
                targetY,
                targetPosition,
                borderRadius: 10,
            });
        case 'processPipeline':
        case 'stateManagement':
            return getBezierPath({
                sourceX,
                sourceY,
                sourcePosition,
                targetX,
                targetY,
                targetPosition,
                curvature: 0.3,
            });
        case 'constantFlow':
        case 'logicFlow':
            return getStraightPath({
                sourceX,
                sourceY,
                targetX,
                targetY,
            });
        case 'glowingOrb':
        case 'laserBeam':
            return getBezierPath({
                sourceX,
                sourceY,
                sourcePosition,
                targetX,
                targetY,
                targetPosition,
                curvature: 0.5,
            });
        case 'unidirectional':
        case 'bidirectional':
            return getStraightPath({
                sourceX,
                sourceY,
                targetX,
                targetY,
            });
        default:
            return getBezierPath({
                sourceX,
                sourceY,
                sourcePosition,
                targetX,
                targetY,
                targetPosition,
                curvature: 0.25,
            });
    }
};

// Custom Edge Component
const CustomEdge = memo(
    ({
         id,
         sourceX,
         sourceY,
         targetX,
         targetY,
         sourcePosition,
         targetPosition,
         style = {},
         data,
         selected,
     }) => {
        const type = data?.type || 'componentCommunication';
        const config = BASE_EDGE_TYPES[type] || BASE_EDGE_TYPES.componentCommunication;

        const [edgePath, labelX, labelY] = getEdgePath(type, {
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        });

        const edgeStyle = {
            ...config.styleConfig,
            ...style,
            strokeWidth: selected
                ? config.styleConfig.strokeWidth + 1
                : config.styleConfig.strokeWidth,
            filter: selected
                ? `drop-shadow(0 0 10px ${config.color})`
                : config.styleConfig.filter,
        };

        return (
            <>
                {/* Definitions for markers and gradients */}
                <defs>
                    {/* Arrowhead marker */}
                    <marker
                        id="arrowhead"
                        markerWidth="10"
                        markerHeight="7"
                        refX="10"
                        refY="3.5"
                        orient="auto"
                        markerUnits="strokeWidth"
                    >
                        <path d="M0,0 L0,7 L10,3.5 z" fill={config.color} />
                    </marker>

                    {/* Laser gradient */}
                    <linearGradient id="laserGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#ff6b6b" />
                        <stop offset="50%" stopColor="#ff4757" />
                        <stop offset="100%" stopColor="#ff6b6b" />
                    </linearGradient>

                    {/* Glow filter for glowingOrb */}
                    <filter id="glowFilter">
                        <feGaussianBlur stdDeviation="4.5" result="coloredBlur" />
                        <feMerge>
                            <feMergeNode in="coloredBlur" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>

                <path
                    id={id}
                    className={`react-flow__edge-path ${config.style || ''} ${
                        selected ? 'selected' : ''
                    }`}
                    d={edgePath}
                    style={edgeStyle}
                    markerStart={config.styleConfig.markerStart}
                    markerEnd={config.styleConfig.markerEnd}
                />
                {data?.label && (
                    <EdgeLabelRenderer>
                        <div
                            className={`edge-label ${type} ${selected ? 'selected' : ''}`}
                            style={{
                                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                                opacity: selected ? 1 : 0.85,
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                color: '#fff',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                fontSize: '12px',
                            }}
                        >
                            {data.label}
                        </div>
                    </EdgeLabelRenderer>
                )}
            </>
        );
    }
);

CustomEdge.displayName = 'CustomEdge';

// Mapping styles to types
export const styleMapping = Object.fromEntries(
    Object.keys(BASE_EDGE_TYPES).map((key) => [key, key])
);

// Function to get effective edge type
export const getEffectiveEdgeType = (edge) => {
    if (!edge) return 'componentCommunication';

    if (edge.data?.type && EDGE_TYPES[edge.data.type]) {
        return edge.data.type;
    }

    if (edge.style && styleMapping[edge.style]) {
        return styleMapping[edge.style];
    }

    if (edge.type && styleMapping[edge.type]) {
        return styleMapping[edge.type];
    }

    return 'componentCommunication';
};

// Function to get edge style
export const getEdgeStyle = (type, isSelected = false) => {
    const config = EDGE_TYPES[type] || EDGE_TYPES.componentCommunication;
    return {
        ...config.styleConfig,
        strokeWidth: isSelected
            ? config.styleConfig.strokeWidth + 1
            : config.styleConfig.strokeWidth,
        filter: isSelected
            ? `drop-shadow(0 0 10px ${config.color})`
            : config.styleConfig.filter,
        className: `${config.style || ''} ${isSelected ? 'selected' : ''}`,
    };
};

// Create the final EDGE_TYPES with components
const EDGE_TYPES = Object.fromEntries(
    Object.entries(BASE_EDGE_TYPES).map(([key, config]) => [
        key,
        { ...config, component: CustomEdge },
    ])
);

export { CustomEdge };
export default EDGE_TYPES;
