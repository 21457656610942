import React from 'react';
import { Handle, Position } from 'reactflow';

// Define the CustomCloud component
const CustomCloud = () => (
    <svg
        viewBox="0 0 180 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
        }}
    >
        <path
            d="M40 70 C20 70 10 60 10 45 C10 30 25 20 40 25 C45 10 60 5 75 10 C90 15 95 30 95 40 C115 40 125 55 120 70 C115 80 45 80 40 70"
            fill="url(#cloudGradient)"
            filter="url(#cloudShadow)"
        />
        <defs>
            <linearGradient id="cloudGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#3498db" />
                <stop offset="100%" stopColor="#2980b9" />
            </linearGradient>
            <filter id="cloudShadow" x="-20%" y="-20%" width="140%" height="140%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                <feOffset dx="2" dy="2" result="offsetblur" />
                <feComponentTransfer>
                    <feFuncA type="linear" slope="0.3" />
                </feComponentTransfer>
                <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </defs>
    </svg>
);

// Define the nodeStyles object
const nodeStyles = {
    rectangle: {
        background: 'linear-gradient(135deg, #20b2aa 0%, #148f89 100%)',
        borderRadius: '8px',
        width: '180px',
        height: '60px',
    },
    circle: {
        background: 'linear-gradient(135deg, #4a90e2 0%, #357abd 100%)',
        borderRadius: '50%',
        width: '100px',
        height: '100px',
    },
    diamond: {
        background: 'linear-gradient(135deg, #9013fe 0%, #6f0fc7 100%)',
        width: '100px',
        height: '100px',
        transform: 'rotate(45deg)',
    },
    parallelogram: {
        background: 'linear-gradient(135deg, #e6a23c 0%, #c88a2f 100%)',
        width: '160px',
        height: '60px',
        transform: 'skew(-20deg)',
    },
    hexagon: {
        background: 'linear-gradient(135deg, #67c23a 0%, #4d9e2a 100%)',
        clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
        width: '120px',
        height: '104px',
    },
    star: {
        background: 'linear-gradient(135deg, #f39c12 0%, #d68910 100%)',
        clipPath: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
        width: '100px',
        height: '100px',
    },
    trapezoid: {
        background: 'linear-gradient(135deg, #8e44ad 0%, #703688 100%)',
        clipPath: 'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)',
        width: '160px',
        height: '80px',
    },
    oval: {
        background: 'linear-gradient(135deg, #2ecc71 0%, #25a85a 100%)',
        borderRadius: '50% / 25%',
        width: '180px',
        height: '80px',
    },
    cloud: {
        background: 'transparent',
        width: '180px',
        height: '100px',
        position: 'relative',
    },
    cylinder: {
        background: 'linear-gradient(135deg, #e74c3c 0%, #c0392b 100%)',
        borderRadius: '24px / 8px',
        width: '140px',
        height: '80px',
    },
};

const CustomNode = ({ data }) => {
    const shape = data.shape || 'rectangle';
    const baseStyle = nodeStyles[shape] || nodeStyles.rectangle;

    const wrapperStyle = {
        ...baseStyle,
        transition: 'all 0.3s ease',
        border: 'none',
        outline: 'none',
        backgroundColor: shape === 'cloud' ? 'transparent' : baseStyle.background,
        padding: 0,
        margin: 0,
        boxShadow: 'none',
        cursor: 'move',
        position: 'relative',
        '&:hover': {
            transform: 'scale(1.02)',
            filter: 'brightness(1.1)',
        },
    };

    const labelWrapperStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: shape === 'diamond'
            ? 'translate(-50%, -50%) rotate(-45deg)'
            : shape === 'parallelogram'
                ? 'translate(-50%, -50%) skew(20deg)'
                : 'translate(-50%, -50%)',
        width: '100%',
        textAlign: 'center',
        color: '#fff',
        fontWeight: '500',
        fontSize: '14px',
        textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
        padding: '0 12px',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        zIndex: 1,
    };

    const handleStyle = {
        background: '#fff',
        border: '2px solid #666',
        width: '8px',
        height: '8px',
        zIndex: 2,
        transition: 'all 0.2s ease',
        boxShadow: '0 0 3px rgba(0,0,0,0.3)',
        '&:hover': {
            transform: 'scale(1.2)',
            borderColor: '#333',
        },
    };

    return (
        <>
            <style>
                {`
                    .react-flow__node {
                        background: transparent;
                        border: none;
                        outline: none;
                        box-shadow: none !important;
                    }
                    .react-flow__node.selected,
                    .react-flow__node:focus,
                    .react-flow__node:focus-visible {
                        outline: none !important;
                        border: none !important;
                        box-shadow: none !important;
                    }
                    .react-flow__node * {
                        outline: none !important;
                    }
                    .react-flow__handle {
                        transition: all 0.2s ease;
                    }
                    .react-flow__handle:hover {
                        transform: scale(1.2);
                        background: #fff;
                        border-color: #333;
                    }
                    .custom-node-wrapper {
                        transition: all 0.3s ease;
                    }
                    .custom-node-wrapper:hover {
                        transform: scale(1.02);
                        filter: brightness(1.1);
                    }
                    .node-label {
                        transition: all 0.3s ease;
                    }
                    .custom-node-wrapper:hover .node-label {
                        text-shadow: 0 2px 4px rgba(0,0,0,0.3);
                    }
                `}
            </style>
            <div
                style={wrapperStyle}
                className="custom-node-wrapper custom-drag-handle"
            >
                <Handle
                    type="target"
                    position={Position.Top}
                    style={handleStyle}
                    className="custom-handle"
                />
                {shape === 'cloud' && <CustomCloud />}
                <div style={labelWrapperStyle} className="node-label">
                    {data.label}
                </div>
                <Handle
                    type="source"
                    position={Position.Bottom}
                    style={handleStyle}
                    className="custom-handle"
                />
            </div>
        </>
    );
};

export default CustomNode;