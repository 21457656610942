// select.js //

import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { Check, ChevronDown, ChevronUp, ChevronsUpDown } from 'lucide-react';
import { cn } from '../utils/utils.js';
import { cva } from "class-variance-authority";


const Select = React.forwardRef(({ children, className, ...props }, ref) => (
    <SelectPrimitive.Root ref={ref} className={cn("relative", className)} {...props}>
        {children}
    </SelectPrimitive.Root>
));
Select.displayName = SelectPrimitive.Root.displayName;

const SelectGroup = React.forwardRef(({ children, className, ...props }, ref) => (
    <div ref={ref} className={cn("space-y-2", className)} {...props}>
        {children}
    </div>
));
SelectGroup.displayName = "SelectGroup";

const SelectValue = React.forwardRef(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Value ref={ref} className={cn("text-sm font-medium text-gray-200", className)} {...props}>
        {children}
    </SelectPrimitive.Value>
));
SelectValue.displayName = SelectPrimitive.Value.displayName;


// Enhanced trigger variants with glowing and animation effects
const triggerVariants = cva(
    "flex items-center justify-between rounded-md text-sm ring-offset-background transition-all duration-200 cursor-pointer",
    {
        variants: {
            variant: {
                default: [
                    "border border-gray-800",
                    "bg-gradient-to-b from-gray-900 to-gray-900/90",
                    "text-gray-200",
                    "shadow-[0_0_0_1px_rgba(32,178,170,0.1)]",
                    "hover:shadow-[0_0_0_1px_rgba(32,178,170,0.2)]",
                    "focus:shadow-[0_0_0_2px_rgba(32,178,170,0.3)]",
                    "transition-transform duration-300",
                ],
                glass: [
                    "backdrop-blur-xl",
                    "bg-white/5",
                    "border border-white/10",
                    "text-gray-200",
                    "hover:bg-white/10",
                    "focus:ring-2 focus:ring-primary-500/30",
                ],
                premium: [
                    "border border-primary-500/20",
                    "bg-gradient-to-b from-gray-900/95 to-gray-900/90",
                    "text-primary-300",
                    "shadow-[0_0_0_1px_rgba(32,178,170,0.1),0_2px_8px_rgba(32,178,170,0.2)]",
                    "hover:shadow-[0_0_0_1px_rgba(32,178,170,0.2),0_4px_16px_rgba(32,178,170,0.4)]",
                ],
            },
            size: {
                default: "h-10 px-3 py-2",
                sm: "h-8 px-2.5 py-1.5 text-xs",
                lg: "h-12 px-4 py-3 text-base",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
);

// Enhanced SelectTrigger with glowing effects and perfect cursor
const SelectTrigger = React.forwardRef(({ className, variant, size, children, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
            triggerVariants({ variant, size, className }),
            // Add glowing border when active
            "group relative",
            variant === "premium" && "after:absolute after:inset-0 after:rounded-md after:border after:border-primary-500/50 after:opacity-0 after:transition-opacity after:duration-300 group-data-[state=open]:after:opacity-100"
        )}
        {...props}
    >
        {children}
        <SelectPrimitive.Icon asChild>
            <ChevronsUpDown className="ml-2 h-4 w-4 text-gray-400 transition-transform duration-200 ease-in-out group-hover:text-primary-400" />
        </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

// Enhanced SelectContent with animations and glowing effects
const SelectContent = React.forwardRef(({ className, children, position = 'popper', ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cn(
                "relative z-50 min-w-[10rem] overflow-hidden rounded-lg border border-primary-medium bg-gray-900/90 p-2 shadow-neon animate-fade-in",
                className,
                "data-[state=open]:animate-fade-in data-[state=closed]:animate-fade-out",
                "animate-in fade-in-0 slide-in-from-top-1",
                "data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2",
                "data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                position === "popper" &&
                "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1"
            )}
            position={position}
            {...props}
        >
            <SelectPrimitive.Viewport
                className={cn(
                    "p-1",
                    position === "popper" &&
                    "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
                )}
            >
                {children}
            </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

// Enhanced SelectLabel with icons and glowing effects
const SelectLabel = React.forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cn(
            "px-4 py-2 text-sm font-semibold text-gray-300",
            className
        )}
        {...props}
    />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

// Enhanced SelectItem with glowing and animated check icon
const SelectItem = React.forwardRef(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(
            "relative flex cursor-pointer select-none items-center rounded-md px-4 py-2 text-sm text-gray-200 transition-colors duration-200 hover:bg-primary-medium hover:text-white focus:bg-primary-medium",
            className
        )}
        {...props}
    >
        <span className="absolute left-2 flex h-5 w-5 items-center justify-center">
            <SelectPrimitive.ItemIndicator>
                <Check className="h-4 w-4 text-primary-400 animate-scale-in" />
            </SelectPrimitive.ItemIndicator>
        </span>
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

// Enhanced SelectSeparator with glowing effect
const SelectSeparator = React.forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator
        ref={ref}
        className={cn("my-1 h-px bg-gray-700", className)}
        {...props}
    />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

// Enhanced Scroll Buttons with icons and glowing effects
const scrollButtonStyles = cn(
    "flex items-center justify-center py-1",
    "text-gray-400 hover:text-primary-400",
    "transition-colors duration-200",
    "bg-gradient-to-b from-gray-900/95 to-gray-900/98"
);

const SelectScrollUpButton = React.forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollUpButton
        ref={ref}
        className={cn(scrollButtonStyles, className)}
        {...props}
    >
        <ChevronUp className="h-4 w-4" />
    </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollDownButton
        ref={ref}
        className={cn(scrollButtonStyles, className)}
        {...props}
    >
        <ChevronDown className="h-4 w-4" />
    </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

// Export all components
export {
    Select,
    SelectGroup,
    SelectValue,
    SelectTrigger,
    SelectContent,
    SelectLabel,
    SelectItem,
    SelectSeparator,
    SelectScrollUpButton,
    SelectScrollDownButton,
};
