import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import './Card.css';

// Enhanced Animations
const shimmer = keyframes`
    0% { background-position: -200% center; }
    100% { background-position: 200% center; }
`;

const pulseGlow = keyframes`
    0%, 100% { box-shadow: 0 0 0 0 rgba(32, 178, 170, 0); }
    50% { box-shadow: 0 0 20px rgba(32, 178, 170, 0.4); }
`;

const floatAnimation = keyframes`
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
`;

// Define variants (keep your existing variants object)
const variants = {
    default: css`
        background: linear-gradient(145deg, rgba(18, 18, 18, 0.95), rgba(18, 18, 18, 0.9));
        border: 1px solid rgba(32, 178, 170, 0.1);
        backdrop-filter: blur(12px);
    `,
    // ... rest of your variants
};

// Enhanced Card Container with DOM prop filtering
const CardContainer = styled.div.withConfig({
    // Specify which props should NOT be passed to the DOM
    shouldComponentUpdate: (prop) =>
        !['elevated', 'rounded', 'interactive', 'loading', 'glow', 'float', 'disabled'].includes(prop)
})`
    width: ${props => props.width || '100%'};
    border-radius: ${props => props.rounded ? '16px' : '12px'};
    position: relative;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    color: ${props => props.variant === 'glass' || props.variant === 'ghost' ? 'rgba(255, 255, 255, 0.9)' : '#fff'};

    ${props => variants[props.variant || 'default']}

    ${props => props.elevated && css`
        box-shadow:
                0 0 0 1px rgba(32, 178, 170, 0.1),
                0 4px 24px rgba(0, 0, 0, 0.2);
    `}

    ${props => props.loading && css`
        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(
                    90deg,
                    transparent,
                    rgba(32, 178, 170, 0.15),
                    transparent
            );
            background-size: 200% 100%;
            animation: ${shimmer} 2s infinite linear;
        }
    `}

    ${props => props.interactive && css`
        cursor: pointer;
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

        &:hover {
            transform: translateY(-4px) scale(1.01);
            box-shadow:
                    0 0 0 1px rgba(32, 178, 170, 0.2),
                    0 8px 32px rgba(0, 0, 0, 0.3),
                    0 4px 16px rgba(32, 178, 170, 0.2);
        }

        &:active {
            transform: translateY(-2px) scale(0.99);
        }
    `}

    ${props => props.glow && css`
        animation: ${pulseGlow} 3s infinite;
    `}

    ${props => (props.float ? css`
        animation: ${floatAnimation} 5s ease-in-out infinite;
    ` : null)}

    ${props => props.disabled && css`
        opacity: 0.5;
        pointer-events: none;
        filter: grayscale(50%);
    `}

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: inherit;
        padding: 1px;
        background: linear-gradient(
                145deg,
                rgba(255, 255, 255, 0.2),
                transparent
        );
        mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }
`;

// Keep your existing CardHeader, CardContent, and CardFooter components
export const CardHeader = styled.div`
    // ... your existing CardHeader styles
`;

export const CardContent = styled.div`
    // ... your existing CardContent styles
`;

export const CardFooter = styled.div`
    // ... your existing CardFooter styles
`;

// Updated Card component with prop filtering
const Card = React.forwardRef(({
                                   children,
                                   width,
                                   variant,
                                   elevated,
                                   rounded,
                                   interactive,
                                   loading,
                                   disabled,
                                   className,
                                   onClick,
                                   glow,
                                   float,
                                   ...props
                               }, ref) => {
    // DOM-safe props object
    const safeProps = {
        className,
        onClick,
        ref,
        width,
        variant,
        ...props
    };

    // Styled-component-only props
    const styledProps = {
        elevated,
        rounded,
        interactive,
        loading,
        disabled,
        glow,
        float: float ? 'true' : undefined
    };

    return (
        <CardContainer
            {...safeProps}
            {...styledProps}
        >
            {children}
        </CardContainer>
    );
});

Card.displayName = 'Card';

Card.propTypes = {
    children: PropTypes.node.isRequired,
    width: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'primary', 'success', 'warning', 'error', 'ghost', 'glass', 'premium']),
    elevated: PropTypes.bool,
    rounded: PropTypes.bool,
    interactive: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    glow: PropTypes.bool,
    float: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

// Remove defaultProps in favor of default parameters
Card.defaultProps = {
    width: '100%',
    variant: 'default'
};

export default Card;