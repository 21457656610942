import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { rightPanelReducer, initialState, ActionTypes } from './RightPanelReducer.js';
import { ANIMATION_DURATION, STORAGE_KEYS } from '../constants/constants.js';
import { savePanelState, loadPanelState, validatePanelState } from '../utils/RightPanelUtils.js';

const RightPanelContext = createContext();

const RightPanelProvider = ({ children }) => {
    const [state, dispatch] = useReducer(rightPanelReducer, initialState);

    // Load saved state on mount
    useEffect(() => {
        const savedState = loadPanelState(STORAGE_KEYS.PANEL_STATE);
        if (savedState && validatePanelState(savedState)) {
            Object.entries(savedState).forEach(([key, value]) => {
                dispatch({ type: `SET_${key.toUpperCase()}`, payload: value });
            });
        }
    }, []);

    // Autosave on state changes
    useEffect(() => {
        if (state.unsavedChanges) {
            const timeoutId = setTimeout(() => {
                const stateToSave = {
                    isFloating: state.isFloating,
                    isExpanded: state.isExpanded,
                    activeView: state.activeView,
                    activeTab: state.activeTab,
                    nodes: state.nodes,
                    edges: state.edges,
                };
                if (validatePanelState(stateToSave)) {
                    savePanelState(STORAGE_KEYS.PANEL_STATE, stateToSave);
                }
            }, ANIMATION_DURATION.SLOW);

            return () => clearTimeout(timeoutId);
        }
    }, [state.unsavedChanges, state.nodes, state.edges]);

    // Error/Success message cleanup
    useEffect(() => {
        if (state.error || state.successMessage) {
            const timeoutId = setTimeout(() => {
                if (state.error) {
                    dispatch({ type: ActionTypes.SET_ERROR, payload: null });
                }
                if (state.successMessage) {
                    dispatch({ type: ActionTypes.SET_SUCCESS_MESSAGE, payload: null });
                }
            }, ANIMATION_DURATION.NORMAL);

            return () => clearTimeout(timeoutId);
        }
    }, [state.error, state.successMessage]);

    const value = {
        ...state,
        dispatch,
    };

    return (
        <RightPanelContext.Provider value={value}>
            {children}
        </RightPanelContext.Provider>
    );
};

RightPanelProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const useRightPanelContext = () => {
    const context = useContext(RightPanelContext);
    if (!context) {
        throw new Error('useRightPanelContext must be used within a RightPanelProvider');
    }
    return context;
};

export { RightPanelContext, RightPanelProvider, useRightPanelContext };