import axios from 'axios';

export const API_CONFIG = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080',
    TIMEOUT: 30000,
    RETRY_ATTEMPTS: 3,
    RETRY_DELAY: 1000,
    MAX_BATCH_SIZE: 10,
    ENDPOINTS: {
        LOGIN: '/api/users/login',
        REGISTER: '/api/users/register',
        PROFILE: '/api/users/profile',
        AI_RESPONSES: '/api/ai/respond',
        AI_CHARACTERISTICS: '/api/settings/ai-characteristics',
        DIAGRAMS: '/api/diagrams',
        NODES: '/api/diagrams/nodes',
        EDGES: '/api/diagrams/edges'
    }
};

export const ENDPOINTS = API_CONFIG.ENDPOINTS;

export const api = axios.create({
    baseURL: API_CONFIG.BASE_URL,
    timeout: API_CONFIG.TIMEOUT,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true
});

// Request interceptor
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
    response => response,
    async error => {
        if (!error.response) {
            return Promise.reject(new Error('Unable to connect to the server'));
        }

        if (error.response?.status === 401) {
            localStorage.clear();
            window.location.href = '/login';
            return Promise.reject(new Error('Session expired'));
        }

        if (error.response?.status === 403) {
            console.error('CORS Error:', error.response.data);
            return Promise.reject(new Error('Access denied'));
        }

        return Promise.reject(error);
    }
);

export const apiService = {
    auth: {
        login: (credentials) => api.post(ENDPOINTS.LOGIN, credentials),
        register: (credentials) => api.post(ENDPOINTS.REGISTER, credentials),
        getProfile: () => api.get(ENDPOINTS.PROFILE)
    },
    ai: {
        createResponse: (messages) => {
            if (!messages?.[0]?.content) {
                return Promise.reject(new Error('Message content is required'));
            }
            return api.post(ENDPOINTS.AI_RESPONSES, { messages });
        },
        getCharacteristics: () => api.get(ENDPOINTS.AI_CHARACTERISTICS)
    },
    diagrams: {
        getAll: () => api.get(ENDPOINTS.DIAGRAMS),
        getById: (id) => api.get(`${ENDPOINTS.DIAGRAMS}/${id}`),
        create: (data) => api.post(ENDPOINTS.DIAGRAMS, data),
        update: (id, data) => api.put(`${ENDPOINTS.DIAGRAMS}/${id}`, data),
        delete: (id) => api.delete(`${ENDPOINTS.DIAGRAMS}/${id}`),
        share: (id, options) => api.post(`${ENDPOINTS.DIAGRAMS}/${id}/share`, options)
    }
};

export default apiService;