import { PANEL_TYPES, VIEW_TYPES } from '../constants/constants.js';

export const ActionTypes = {
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    TOGGLE_FLOATING: 'TOGGLE_FLOATING',
    TOGGLE_EXPANDED: 'TOGGLE_EXPANDED',
    SET_ACTIVE_VIEW: 'SET_ACTIVE_VIEW',
    UPDATE_NODES: 'UPDATE_NODES',
    UPDATE_EDGES: 'UPDATE_EDGES',
    SET_SELECTED_DIAGRAM: 'SET_SELECTED_DIAGRAM',
    SET_SELECTED_EDGE: 'SET_SELECTED_EDGE',
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
    SET_SUCCESS_MESSAGE: 'SET_SUCCESS_MESSAGE',
    UPDATE_MESSAGE_GROUPS: 'UPDATE_MESSAGE_GROUPS',
    SET_ACTIVE_RESPONSE_GROUP: 'SET_ACTIVE_RESPONSE_GROUP',
    ADD_AI_RESPONSE: 'ADD_AI_RESPONSE',
    MARK_UNSAVED_CHANGES: 'MARK_UNSAVED_CHANGES',
    CLEAR_UNSAVED_CHANGES: 'CLEAR_UNSAVED_CHANGES',
    RESET_PANEL: 'RESET_PANEL',
    SET_AI_MODE: 'SET_AI_MODE',
    UPDATE_RESPONSES: 'UPDATE_RESPONSES',
    ADD_RESPONSES: 'ADD_RESPONSES',
    SET_WINNER: 'SET_WINNER',
    CLEAR_RESPONSES: 'CLEAR_RESPONSES'
};

export const initialState = {
    // Core Panel State
    activeTab: PANEL_TYPES.NODES,
    isFloating: false,
    isExpanded: false,
    activeView: VIEW_TYPES.DIAGRAM,
    unsavedChanges: false,

    // Response Management
    messageGroups: new Map(),
    activeResponseGroup: null,
    responseComparisons: [],
    aiResponses: [],
    responses: [],

    // Diagram State
    selectedDiagram: null,
    selectedEdge: null,
    nodes: [],
    edges: [],

    // UI State
    isLoading: false,
    error: null,
    successMessage: null,
    isAIMode: false,
};

export function rightPanelReducer(state, action) {
    switch (action.type) {
        case ActionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload,
            };

        case ActionTypes.TOGGLE_FLOATING:
            return {
                ...state,
                isFloating: !state.isFloating,
            };

        case ActionTypes.TOGGLE_EXPANDED:
            return {
                ...state,
                isExpanded: !state.isExpanded,
            };

        case ActionTypes.SET_ACTIVE_VIEW:
            return {
                ...state,
                activeView: action.payload,
            };

        case ActionTypes.UPDATE_NODES:
            return {
                ...state,
                nodes: action.payload,
                unsavedChanges: true,
            };

        case ActionTypes.UPDATE_EDGES:
            return {
                ...state,
                edges: action.payload,
                unsavedChanges: true,
            };

        case ActionTypes.SET_SELECTED_DIAGRAM:
            return {
                ...state,
                selectedDiagram: action.payload,
            };

        case ActionTypes.SET_SELECTED_EDGE:
            return {
                ...state,
                selectedEdge: action.payload,
            };

        case ActionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case ActionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case ActionTypes.SET_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload,
            };

        case ActionTypes.UPDATE_MESSAGE_GROUPS:
            return {
                ...state,
                messageGroups: new Map([...state.messageGroups, ...action.payload]),
            };

        case ActionTypes.SET_ACTIVE_RESPONSE_GROUP:
            return {
                ...state,
                activeResponseGroup: action.payload,
            };

        case ActionTypes.ADD_AI_RESPONSE:
            return {
                ...state,
                aiResponses: [...state.aiResponses, action.payload],
            };

        case ActionTypes.MARK_UNSAVED_CHANGES:
            return {
                ...state,
                unsavedChanges: true,
            };

        case ActionTypes.CLEAR_UNSAVED_CHANGES:
            return {
                ...state,
                unsavedChanges: false,
            };

        case ActionTypes.RESET_PANEL:
            return {
                ...state,
                nodes: [],
                edges: [],
                selectedDiagram: null,
                selectedEdge: null,
                unsavedChanges: false,
            };

        case ActionTypes.SET_AI_MODE:
            return {
                ...state,
                isAIMode: action.payload,
            };

        case ActionTypes.UPDATE_RESPONSES:
            return {
                ...state,
                responses: action.payload
            };

        case ActionTypes.ADD_RESPONSES:
            return {
                ...state,
                responses: [...state.responses, ...action.payload]
            };

        case ActionTypes.SET_WINNER:
            return {
                ...state,
                responses: state.responses.map(response =>
                    response.timestamp === action.payload.timestamp &&
                    response.role === 'assistant'
                        ? { ...response, isWinner: response.type === action.payload.winner }
                        : response
                )
            };

        case ActionTypes.CLEAR_RESPONSES:
            return {
                ...state,
                responses: [],
                messageGroups: new Map(),
                activeResponseGroup: null
            };

        default:
            return state;
    }
}
